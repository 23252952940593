import { defineStore } from 'pinia'
import { Taxonomy, Tag } from '@/types/Taxonomy'
import api from '@/util/api'
import sleep from '@/util/sleep'
import BN from 'bn.js'

interface TaxonomyStoreState {
	loaded: boolean
	taxonomy: Taxonomy[] | null
	filters: Map<number, boolean>
	modifiedFilters: Map<number, boolean>
}

export const useTaxonomyStore = defineStore('taxonomy', {
	state: () =>
		({
			loaded: false,
			taxonomy: null,
			filters: new Map(),
			modifiedFilters: new Map(),
		} as TaxonomyStoreState),

	actions: {
		async fetchTaxonomy(force: boolean | null): Promise<Taxonomy[]> {
			if (!this.loaded || force) {
				this.taxonomy = await api.fetchTaxonomy()
				this.loaded = true
			}

			return this.taxonomy
		},

		resetFilters() {
			this.filters = new Map()
			this.modifiedFilters = new Map()
		},

		toggleModifiedFilter(tag: Tag) {
			let active = true
			if (this.modifiedFilters.has(tag.id)) {
				active = !this.modifiedFilters.get(tag.id)
			}

			if (active) {
				this.modifiedFilters.set(tag.id, active)
			} else {
				this.modifiedFilters.delete(tag.id)
			}
		},

		applyFilters() {
			this.filters = new Map(JSON.parse(JSON.stringify(Array.from(this.modifiedFilters.entries()))))
		},
	},

	getters: {
		activeFilters() {
			let result: any = {}
			this.filters.forEach((value, key) => {
				if (value) {
					result[key] = value
				}
			})

			return result
		},

		editingFilters() {
			let result: any = {}
			this.modifiedFilters.forEach((value: boolean, key: number) => {
				if (value) {
					result[key] = value
				}
			})

			return result
		},
	},
})
