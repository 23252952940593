import Currency from '@/types/Currency'

export default function currencyFormat(val: number | String, forceDecimals: boolean = false): String {
	return new Intl.NumberFormat('en-US', {
		minimumIntegerDigits: 1,
		minimumFractionDigits: 2,
		style: 'decimal',
		trailingZeroDisplay: !forceDecimals ? 'stripIfInteger' : 'auto',
	}).format(String(val))
}

export function humanReadablePrice(amt: string | number) {
	return new Currency(String(amt)).toDecimal()
}
