<template>
	<modal class="z-50">
		<template #default="handlers">
			<div class="bg-white rounded-xl pt-4 overflow-hidden drop-shadow-md w-full">
				<h1 class="text-2xl font-semibold text-center">Order Summary</h1>
				<div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
					<button
						@click.stop="$emit('close')"
						type="button"
						class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
					>
						<svg
							class="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke-width="1.5"
							stroke="currentColor"
							aria-hidden="true"
						>
							<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</button>
				</div>
				<div>
					<div class="mt-4 border-t border-gray-200 bg-white shadow-sm">
						<dl class="space-y-6 px-4 pt-6 sm:px-6">
							<div class="flex items-center justify-between">
								<dt class="text-base font-medium">1 Item</dt>
							</div>
						</dl>
						<ul role="list" class="divide-y divide-gray-200">
							<li class="flex px-4 py-6 sm:px-6">
								<div class="flex-shrink-0">
									<img :src="state.asset.image" :alt="state.asset.name" class="w-32 rounded-md" />
								</div>
								<div class="ml-6 flex flex-1 flex-col">
									<div class="flex">
										<div class="min-w-0 flex-1">
											<h4 class="text-base">
												<a
													:href="state.asset.token_id"
													class="font-medium text-gray-700 hover:text-gray-800"
													>#{{ state.asset.id }} - {{ state.asset.name }}</a
												>
											</h4>
											<p class="mt-1 text-base text-gray-500">{{ state.storefront.name }}</p>
										</div>
									</div>
								</div>
							</li>
						</ul>
						<dl class="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
							<div class="flex items-center justify-between">
								<dt class="text-base font-medium">Available BPX</dt>
								<dd class="text-base font-medium text-gray-900">{{ $format(32000) }} BPX</dd>
							</div>
							<div class="flex items-center justify-between border-t border-gray-200 pt-6">
								<dt class="text-base font-medium">Total Price</dt>
								<dd class="text-base font-medium text-gray-900">
									{{ $format(new Currency(props.state.bestListing.price)) }} BPX
								</dd>
							</div>
						</dl>

						<div class="border-t border-gray-200 px-4 py-6 sm:px-6">
							<button
								@click="processBPXPurchase(state)"
								type="button"
								class="w-full rounded-md border border-transparent bg-sky-600 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 focus:ring-offset-gray-50"
							>
								Confirm order
							</button>
						</div>
					</div>
				</div>
			</div>
		</template>
	</modal>
</template>
<script lang="ts">
import { defineComponent, onMounted, PropType } from 'vue'
import Currency from '@/types/Currency'
import { useAssetStore } from '@/stores/AssetStore'
import { useAuthStore } from '@/stores/AuthStore'

export default defineComponent({
	props: {
		state: {
			required: true,
			type: Object,
		},
	},
	setup(props) {
		return {
			props,
			Currency,
		}
	},
	methods: {
		processBPXPurchase: async (state) => {
			await useAssetStore()
				.purchaseListing(
					1,
					state.bestListing.id,
					useAuthStore()?.user?.id,
					1 //always 1 until we implement shopping cart
				)
				.then((res) => {
					if (200 != res.statusCode) {
						state.purchaseError = true
						state.purchaseErrorMessage = res.message
						return false
					} else {
						state.purchaseError = false
						state.purchaseError = null

						//show asset purchase successfully message on our asset page
						window.location.href = `/store/${state.storefront.slug}/${state.asset.id}?notice=asset_purchase_success`
					}
				})
				.catch((err) => {
					state.listingError = true
					state.listingErrorMessage = err.message
					window.scrollTo({ top: 0, behavior: 'smooth' })
					return false
				})
		},
	},
})
</script>
