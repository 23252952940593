<template>
	<div class="p-6">
		<feedback-modal @close="clearFeedback" v-if="null !== state.feedback">
			<template #title>
				<span class="text-slate-900 font-poppins">
					<span v-if="state.feedback.success">
						<span
							class="inline-flex justify-center items-center text-green-600 bg-green-100 aspect-square px-3 rounded-full"
							><i class="fa-solid fa-check"></i
						></span>
						<span class="ml-3"> Congratulations! </span>
					</span>
					<span v-else>
						<span
							class="inline-flex justify-center items-center text-red-600 bg-red-100 aspect-square px-3 rounded-full"
							><i class="fa-regular fa-circle-exclamation"></i
						></span>
						<span class="ml-3"> Something went wrong! </span>
					</span>
				</span>
			</template>
			<template #body>
				<div class="text-xl my-4">
					<p>{{ state.feedback.message }}</p>
				</div>
			</template>
			<template #buttons="{ close }">
				<button class="btn-primary-lg mt-2" @click.stop="close">Dismiss</button>
			</template>
		</feedback-modal>

		<div class="max-w-screen-lg mx-auto">
			<h1 class="text-2xl font-semibold border-b-2 border-gray-300 pb-1">Blokpax Marketplace Store Credit</h1>
		</div>

		<section
			class="max-w-screen-lg mx-auto pt-6 font-light sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 sm:justify-center md:grid-cols-3"
		>
			<article
				v-for="(opt, i) in options"
				@click="buy(opt.amount)"
				class="relative max-w-sm rounded-lg overflow-hidden shadow-lg cursor-pointer flex flex-col mx-auto mb-8 sm:mb-0 group"
				v-key="i"
			>
				<p
					class="tracking-wide text-sm font-bold absolute top-0 left-0 block px-4 py-1 bg-red-500 text-white w-full text-center"
					v-if="opt.mostPopular"
				>
					Most Popular
				</p>

				<p
					class="tracking-wide text-sm font-bold absolute top-0 left-0 block px-4 py-1 bg-red-500 text-white w-full text-center"
					v-else-if="opt.bestValue"
				>
					Best Value
				</p>

				<div class="transition-all bg-gray-100 group-hover:bg-gray-200">
					<img :src="opt.image" :alt="`$${opt.amount} store credit option`" />
				</div>
				<div class="px-6 p-4 grow">
					<p class="text-3xl font-bold font-poppins">${{ opt.amount }}</p>
					<p class="mt-2 text-sm font-medium">Blokpax Marketplace Store Credit</p>
					<p class="mt-4 text-base text-green-600 font-bold" v-if="opt.bonus && opt.bonus > 0">
						+${{ opt.bonus }} BONUS! Total Credit ${{ opt.amount + opt.bonus }}
					</p>
				</div>
				<div class="px-6 p-4 justify-end">
					<button
						class="transition-all text-sm font-bold shadow rounded-full px-4 py-2 group-hover:bg-sky-400 group-hover:text-white"
					>
						Buy Now
					</button>
				</div>
			</article>
		</section>
	</div>
</template>
<script lang="ts" setup>
import { useMarketStore } from '@/stores/MarketStore'
import { reactive, defineComponent, onMounted, PropType } from 'vue'
import FeedbackModal from '@/components/modals/FeedbackModal.vue'
import { useRoute, useRouter } from 'vue-router'

const marketStore = useMarketStore()
const route = useRoute()
const router = useRouter()

const state = reactive({
	feedback: null as null | { success: boolean; message: string },
})

const options: Array<{
	amount: number
	bonus: number | null
	image: string
	mostPopular: boolean
	bestValue: boolean
}> = [
	{
		amount: 25,
		bonus: 0,
		image: '/image/bpx_store-credit_25.webp',
		mostPopular: false,
		bestValue: false,
	},
	{
		amount: 50,
		bonus: 0,
		image: '/image/bpx_store-credit_50.webp',
		mostPopular: false,
		bestValue: false,
	},
	{
		amount: 100,
		bonus: 10,
		image: '/image/bpx_store-credit_100.webp',
		mostPopular: true,
		bestValue: false,
	},
	{
		amount: 250,
		bonus: 35,
		image: '/image/bpx_store-credit_250.webp',
		mostPopular: false,
		bestValue: false,
	},
	{
		amount: 500,
		bonus: 100,
		image: '/image/bpx_store-credit_500.webp',
		mostPopular: false,
		bestValue: false,
	},
	{
		amount: 1000,
		bonus: 250,
		image: '/image/bpx_store-credit_1000.webp',
		mostPopular: false,
		bestValue: true,
	},
]

onMounted(() => {
	if (route.query.success !== undefined) {
		state.feedback = {
			success: true,
			message: String(route.query.success),
		}
	} else if (route.query.error !== undefined) {
		state.feedback = {
			success: false,
			message: String(route.query.error),
		}
	}
})

function clearFeedback() {
	router.replace({
		path: route.fullPath,
	})

	state.feedback = null
}

async function buy(amount: number) {
	const response = await marketStore.initiateStoreCreditPurchase(amount)

	if (response.success) {
		window.top.location = response.redirect
		return
	}

	alert(response.message)
}
</script>
