<template>
	<div class="flex justify-center items-center space-x-4">
		<a href="https://app.blokpax.com/sign-up" title="Sign up to get started!" class="text-white p-3"> Sign up </a>

		<button class="btn-primary-lg hidden lg:block" @click="handleLoginClicked">
			<i class="fa fa-user pr-1"></i>
			Login
		</button>
	</div>
</template>
<script lang="ts">
import { useAuthStore } from '@/stores/AuthStore'
import { defineComponent, onMounted, PropType } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
	setup(props) {
		const authStore = useAuthStore()
		const route = useRoute()

		async function handleLoginClicked() {
			authStore.redirectToLogin(route.path)
		}

		return {
			handleLoginClicked,
		}
	},
})
</script>
