import { useExchangeStore } from '@/stores/ExchangeStore'
import { MarketCurrency, useNewWalletStore } from '@/stores/NewWalletStore'
import Currency from '@/types/Currency'
import currencyFormat, { humanReadablePrice } from '@/util/currencyFormat'
import { BN } from 'bn.js'

const CURRENCY_TYPE_USD: String = 'USD'
const CURRENCY_TYPE_BPX: String = 'BPX'

type CurrencyType = 'USD' | 'BPX'

export class PaymentCurrency {
	_exchangeStore: object
	_walletStore: object
	currencyID: string
	type: CurrencyType

	constructor(currencyId: string, type: CurrencyType) {
		this.currencyID = currencyId
		this.type = type
	}

	id(): string {
		return this.currencyID
	}

	name(): string {
		return this.currency()?.name ?? null
	}

	isUSD(): boolean {
		return this.type == CURRENCY_TYPE_USD
	}

	isBPX(): boolean {
		return this.type == CURRENCY_TYPE_BPX
	}

	balance(): null | string {
		const b = this.currency()?.available ?? null
		return b ? String(b) : b
	}

	currency(): null | MarketCurrency {
		return this.walletStore().balancesByType[this.currencyID] ?? null
	}

	async toBPX(usdAmt: string | number | null) {
		// console.log('converting usd to bpx', usdAmt)
		return await this.exchStore().bpx(String(usdAmt))
	}

	async toUSD(bpxAmt: string | number | null, isDecimal: boolean = false) {
		// console.log('converting currency', bpxAmt);
		return await this.exchStore().usd(String(bpxAmt), isDecimal)
	}

	exchStore() {
		if (!this._exchangeStore) {
			this._exchangeStore = useExchangeStore()
		}

		return this._exchangeStore
	}

	walletStore() {
		if (!this._walletStore) {
			this._walletStore = useNewWalletStore()
		}

		return this._walletStore
	}

	format(amt: string | null): string {
		return this.balance()
	}
}

export class BPXCurrency extends PaymentCurrency {
	constructor(currencyID: string) {
		super(currencyID, CURRENCY_TYPE_BPX as CurrencyType)
	}

	async usd(amt: string | null = null) {
		return await this.toUSD(amt === null ? this.balance() : amt)
	}

	async bpx(amt: string | null = null) {
		return amt === null ? this.balance() : amt
	}

	format(amt: string | null, isDecimal: boolean = false): string {
		let returnAmt = amt || this.balance()

		return currencyFormat(!isDecimal ? humanReadablePrice(returnAmt) : returnAmt) + ' BPX'
	}
}

export class USDCurrency extends PaymentCurrency {
	constructor(currencyID: string) {
		super(currencyID, CURRENCY_TYPE_USD as CurrencyType)
	}

	async usd(amt: string | null = null) {
		return amt === null ? this.balance() : amt
	}

	async bpx(amt: string | null = null) {
		return await this.toBPX(amt === null ? this.balance() : amt)
	}

	format(amt: string | null, isDecimal: boolean = false): string {
		return '$' + currencyFormat(isDecimal ? amt : amt / 100)
	}
}

export function usePaymentModule() {
	const walletStore = useNewWalletStore()

	return {
		paymentCurrencies: {
			[walletStore.CURRENCY_USABLE_BPX]: new BPXCurrency(walletStore.CURRENCY_USABLE_BPX),
			[walletStore.CURRENCY_STORE_CREDIT]: new USDCurrency(walletStore.CURRENCY_STORE_CREDIT),
			[walletStore.CURRENCY_PENDING_AUCTION_CREDIT]: new USDCurrency(walletStore.CURRENCY_PENDING_AUCTION_CREDIT),
		},
	}
}

export type PaymentAmounts = {
	[currencyID: string]: { currency: PaymentCurrency; bpx_amount: string; usd_amount: string }
}
export async function allocateBpxPayment(
	paymentMethods: PaymentCurrency[],
	bpxAmt: string | number,
	isDecimal: boolean = false
): Promise<{ outstanding: number; payments: PaymentAmounts }> {
	let paymentAmounts: PaymentAmounts = {}

	let bpxCurrency = isDecimal ? new BN(Currency.fromDecimal(bpxAmt).toString()) : new BN(bpxAmt)
	let remaining = new BN(bpxCurrency.toString())

	for (let methodIdx = 0; methodIdx < paymentMethods.length; methodIdx++) {
		if (remaining <= 0) {
			break
		}

		const method = paymentMethods[methodIdx]
		const methodBalance = new BN(await method.bpx()) //Number((new Currency(await method.bpx())).toDecimal())

		let useAmt
		let useAmtUSD
		// console.log('method balance', methodBalance, 'remaining', remaining, 'balance.gte(remaining)', methodBalance.gte(remaining))
		if (remaining.lte(methodBalance)) {
			// if we have more than enough of this currency to cover the remainder of the bid
			// use the remaining amount of the currency, and set the remaining amount to 0
			useAmt = new BN(remaining.toString())
			useAmtUSD = await method.toUSD(useAmt.toString(), false)
			remaining = new BN('0')
		} else {
			// if we don't have enough of this currency to cover the remainder of the bid
			// use whatever we have
			// then subtract that value (in bpx) from the remaining amount
			useAmt = methodBalance
			useAmtUSD = await method.toUSD(useAmt)
			remaining = remaining.sub(new BN(useAmt))
		}

		paymentAmounts[method.id()] = {
			currency: method,
			bpx_amount: String(useAmt),
			usd_amount: useAmtUSD,
		}
	}

	return {
		outstanding: Math.max(remaining.toNumber(), 0),
		payments: paymentAmounts,
	}
}
