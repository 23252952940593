<template>
	<slot v-if="!finished" :duration="duration" :leadingZero="leadingZero" :formattedDuration="formattedDuration">
		{{ formattedDuration }}
	</slot>
	<slot name="finished" v-if="finished"> Now </slot>
</template>
<script lang="ts">
import { PropType, computed, onUnmounted } from 'vue'
import { DateTime } from 'ts-luxon'
import { countdown } from '@/util/countdown'
import { useAuctionStatusStore } from '@/stores/AuctionStatusStore'

export default {
	props: {
		to: {
			type: Object as PropType<DateTime>,
			required: true,
		},
	},
	emits: ['finished'],
	setup(props, { emit: $emit }) {
		const { duration, destruct, finished } = countdown(props.to)

		onUnmounted(() => {
			destruct()
		})

		function leadingZero(i: number | undefined) {
			if (i == undefined) {
				return '00'
			}

			if (i < 10) {
				return `0${i}`
			}

			return i
		}

		const formattedDuration = computed(() => {
			if (!duration.value) return ''

			let string = [
				leadingZero(duration.value.hours),
				leadingZero(duration.value.minutes),
				leadingZero(duration.value.seconds),
			].join(':')

			if (duration.value.days) {
				string = `${duration.value.days} day${duration.value.days != 1 ? 's' : ''}, ${string}`
			}

			return string
		})

		return {
			finished,
			duration,
			leadingZero,
			formattedDuration,
		}
	},
}
</script>
