<template>
	<div class="max-w-7xl mx-auto px-6">
		<div class="mt-6">
			<h1 class="text-center md:text-left text-4xl font-bold">Live Drops</h1>

			<div class="mt-4 sm:grid grid-cols-2 gap-6 space-y-5 sm:space-y-0">
				<a
					href="https://collecthth.com/football"
					class="block relative bg-[url(/image/football-presale.gif)] bg-no-repeat sm:bg-[right_-3rem_top] md:bg-center bg-cover h-48 sm:h-64 lg:h-96 rounded-xl overflow-hidden shadow-md shadow-gray-600 group"
				>
					<div class="absolute inset-0 bg-gradient-to-b from-transparent to-black/70"></div>
					<div class="absolute bottom-0 m-4 p-4 bg-black/50 rounded-xl">
						<h1 class="text-lg sm:text-2xl text-white font-semibold">Home Team Heroes Football 2023</h1>
						<p class="my-4">
							<span
								class="bg-white text-black font-semibold rounded-full px-8 py-3 shadow group-hover:bg-red-700 group-hover:text-white"
							>
								Buy and Rip Instantly!
							</span>
						</p>
					</div>
				</a>

				<a
					href="https://collecthth.com/baseball"
					class="block relative bg-[url(/image/baseball-buy-hero.gif)] bg-no-repeat sm:bg-[right_-3rem_top] md:bg-center bg-cover h-48 sm:h-64 lg:h-96 rounded-xl overflow-hidden shadow-md shadow-gray-600 group"
				>
					<div class="absolute inset-0 bg-gradient-to-b from-transparent to-black/70"></div>
					<div class="absolute bottom-0 m-4 p-4 bg-black/50 rounded-xl">
						<h1 class="text-lg sm:text-2xl text-white font-semibold">Home Team Heroes Baseball 2023</h1>
						<p class="my-4">
							<span
								class="bg-white text-black font-semibold rounded-full px-8 py-3 shadow group-hover:bg-sky-600 group-hover:text-white"
							>
								Buy and Rip Instantly!
							</span>
						</p>
					</div>
				</a>
			</div>
		</div>

		<div class="mt-16" v-if="false">
			<h1 class="text-center md:text-left text-4xl font-bold">Upcoming Drops</h1>
			<div class="mt-2 space-y space-y-4 md:space-y-0 md:mt-4 md:grid grid-cols-2 gap-6">
				<div
					class="relative bg-[url(/image/football-presale.gif)] bg-no-repeat md:bg-center bg-cover h-64 rounded-xl overflow-hidden shadow-md shadow-gray-600"
				>
					<div class="absolute inset-0 bg-gradient-to-b from-transparent to-black/70"></div>
					<div class="absolute bottom-0 m-4 p-4 bg-black/50 rounded-xl md:bg-transparent">
						<h1 class="text-2xl text-white font-semibold">Home Team Heroes Football 2023</h1>
						<p class="mt-3">
							<a
								href="https://collecthth.com/"
								target="_blank"
								class="bg-white text-black font-semibold rounded-full px-8 py-3 shadow disabled:bg-gray-200 disabled:text-gray-500"
							>
								Order Presale <strong>Open Now</strong>!
							</a>
						</p>
					</div>
				</div>

				<div
					class="relative bg-[url(/image/basketball-presale.gif)] bg-no-repeat md:bg-center bg-cover h-64 rounded-xl overflow-hidden shadow-md shadow-gray-600"
				>
					<div class="absolute inset-0 bg-gradient-to-b from-transparent to-black/70"></div>
					<div class="absolute bottom-0 m-4 p-4 bg-black/50 rounded-xl md:bg-transparent">
						<h1 class="text-2xl text-white font-semibold">Home Team Heroes Basketball 2023</h1>
						<p class="mt-3">
							<button
								class="bg-white text-black font-semibold rounded-full px-8 py-3 shadow disabled:bg-gray-200 disabled:text-gray-500"
								disabled
							>
								Order Presale Coming Soon!
							</button>
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="mt-16">
			<h1 class="text-center md:text-left text-4xl font-bold">Marketplace: Trending Collections</h1>
			<div
				class="mt-2 space-y-4 grid grid-cols-1 gap-6 items-stretch md:grid-cols-4 md:space-y-0 md:mx-0 md:mt-4"
			>
				<router-link
					custom
					v-slot="{ href }"
					v-for="storefront in marketStore.featuredStorefronts"
					:key="`storefront-${storefront.id}`"
					:to="{
						name: 'storefront',
						params: { slug: storefront.slug },
					}"
				>
					<a
						:href="href"
						class="cursor-pointer flex flex-col rounded-xl overflow-hidden shadow-md shadow-gray-600 group"
					>
						<div class="flex-shrink-0 flex-grow-0 z-10">
							<img :src="storefront.featured_image" />
						</div>
						<div class="flex flex-grow p-4 flex-col bg-white">
							<h2 class="font-medium">{{ storefront.name }}</h2>
							<p class="font-medium text-gray-500 text-sm">
								{{ storefront.group_name }}
							</p>
							<p class="flex-grow flex-shrink"></p>
							<p>
								<button
									class="text-left mt-2 bg-sky-700 group-hover:bg-sky-600 px-4 py-1 rounded-xl text-white text-sm shadow"
								>
									Browse now
								</button>
							</p>
						</div>
					</a>
				</router-link>
			</div>
		</div>

		<div class="mt-16" v-if="state.rewardAssets.length > 0">
			<h1 class="text-center md:text-left text-4xl/10 font-bold mb-4">
				Reward Store

				<router-link
					:to="{
						name: 'storefront',
						params: { slug: 'reward-room' },
					}"
					class="hidden md:inline-block font-light text-gray-800 text-2xl/10 align-middle ml-4 hover:text-sky-600"
				>
					All Rewards
					<span class="text-lg/10"><i class="far fa-chevron-right"></i></span>
				</router-link>
			</h1>
			<div class="mt-2 grid grid-cols-2 gap-6 place-items-start md:grid-cols-6 md:mx-0 md:mt-4 items-">
				<home-rewards-store-listing
					v-for="asset in state.rewardAssets"
					:asset="asset.name"
					collection="Blokpax Reward Room"
					:img="asset.image"
					:bpx_price="`${asset.floor_listing?.price ?? 0}`"
					:url="'/store/reward-room/' + asset.id"
				/>
			</div>
			<div class="mt-8 md:hidden">
				<router-link
					custom
					v-slot="{ navigate }"
					:to="{
						name: 'storefront',
						params: { slug: 'reward-room' },
					}"
				>
					<button
						class="border border-sky-700 hover:border-sky-600 py-2 px-4 text-xl font-poppins rounded-lg"
						@click="navigate"
					>
						All Rewards
						<span class="text-lg/10"><i class="far fa-chevron-right"></i></span>
					</button>
				</router-link>
			</div>
		</div>

		<div class="mt-16 md:grid grid-cols-2 gap-16 space-y-16 md:space-y-0">
			<div>
				<h1 class="text-center md:text-left text-4xl font-bold">Recent Notable Sales</h1>
				<div class="grid grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-16 mt-8">
					<home-notable-sale
						v-for="sale in recentNotableSales"
						:asset="sale.asset"
						:collection="sale.collection"
						:img="sale.image"
						:bpx_price="sale.bpx_unit_price * 1e9"
						:url="sale.url"
					/>
				</div>
			</div>
			<div>
				<h1 class="text-center md:text-left text-4xl font-bold">Resources</h1>
				<div class="mt-6 grid grid-cols-[1fr,4fr] gap-x-4 gap-y-12 items-center font-bold text-lg">
					<div>
						<a
							href="https://youtu.be/HYCFOyGlW4o?si=33LlaL1nKLor8Mhd"
							rel="nofollow noreferrer"
							target="_blank"
							class="cursor-pointer"
						>
							<img
								src="/image/getting-started-video-300x.webp"
								class="rounded-full shadow-md shadow-gray-400"
							/>
						</a>
					</div>
					<div>
						<a
							href="https://youtu.be/HYCFOyGlW4o?si=33LlaL1nKLor8Mhd"
							rel="nofollow noreferrer"
							target="_blank"
							class="cursor-pointer"
						>
							<p class="text-gray-400 text-sm font-normal">Getting Started?</p>
							<p>Watch This First!</p>
						</a>
					</div>

					<div>
						<a href="https://blokpax.gitbook.io/hth-quickstart/" target="_blank" class="cursor-pointer">
							<img
								src="/image/collectors_guide-300x.webp"
								class="rounded-full shadow-md shadow-gray-400"
							/>
						</a>
					</div>
					<div>
						<a href="https://blokpax.gitbook.io/hth-quickstart/" target="_blank" class="cursor-pointer">
							<p class="text-gray-400 text-sm font-normal">Home Team Heroes</p>
							<p>HTH Collector's Guide</p>
						</a>
					</div>

					<div>
						<a
							href="https://blokpax.com/store/hth-baseball-2023-color-pops/leaderboard"
							target="_blank"
							class="cursor-pointer"
						>
							<img
								src="/image/cp_point_updates-baseball.webp"
								class="rounded-full shadow-md shadow-gray-400"
							/>
						</a>
					</div>
					<div>
						<a
							href="https://blokpax.com/store/hth-baseball-2023-color-pops/leaderboard"
							target="_blank"
							class="cursor-pointer"
						>
							<p class="text-gray-400 text-sm font-normal">HTH: Baseball</p>
							<p>Color Pop Points Updates</p>
						</a>
					</div>
					<div>
						<a
							href="https://blokpax.com/store/hth-football-2023-color-pops/leaderboard"
							target="_blank"
							class="cursor-pointer"
						>
							<img
								src="/image/cp_point_updates-football.webp"
								class="rounded-full shadow-md shadow-gray-400"
							/>
						</a>
					</div>
					<div>
						<a
							href="https://blokpax.com/store/hth-football-2023-color-pops/leaderboard"
							target="_blank"
							class="cursor-pointer"
						>
							<p class="text-gray-400 text-sm font-normal">HTH: Football</p>
							<p>Color Pop Points Updates</p>
						</a>
					</div>

					<div>
						<a
							href="https://blokpax.gitbook.io/hth-quickstart/points-system"
							target="_blank"
							class="cursor-pointer"
						>
							<img
								src="/image/scoring_explained-300x.webp"
								class="rounded-full shadow-md shadow-gray-400"
							/>
						</a>
					</div>
					<div>
						<a
							href="https://blokpax.gitbook.io/hth-quickstart/points-system"
							target="_blank"
							class="cursor-pointer"
						>
							<p class="text-gray-400 text-sm font-normal">Home Team Heroes</p>
							<p>HTH Scoring Explained</p>
						</a>
					</div>

					<div>
						<a href="https://set-builder.collecthth.com/" target="_blank" class="cursor-pointer">
							<img
								src="/image/collector_leaderboard-300x.webp"
								class="rounded-full shadow-md shadow-gray-400"
							/>
						</a>
					</div>
					<div>
						<a href="https://set-builder.collecthth.com/" target="_blank" class="cursor-pointer">
							<p class="text-gray-400 text-sm font-normal">Home Team Heroes</p>
							<p>HTH Collector Leaderboards</p>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { computed, defineComponent, onMounted, PropType, reactive } from 'vue'
import { useAuctionStore } from '@/stores/AuctionStore'
import { useMarketStore } from '@/stores/MarketStore'
import HomeNotableSale from '@/components/HomeNotableSale.vue'
import HomeRewardsStoreListing from '@/components/HomeRewardsStoreListing.vue'
import StorefrontAsset from '@/components/StorefrontAsset.vue'
import api from '@/util/api'

import recentNotableSales from '@/static/recent-notable-sales.json'
import { hasRelative } from 'ts-luxon/dist/impl/util'

const marketStore = useMarketStore()

const state = reactive({
	rewardAssets: [],
})

onMounted(async () => {
	marketStore.load()
	state.rewardAssets = await marketStore.loadFeaturedListings()
})
</script>
