<template>
	<div class="space-y-6">
		<auction-listing-item
			v-for="auction in props.auctions"
			:auction="auction"
			:watched="watched.indexOf(auction.id) >= 0"
			:key="`auction-listing-${auction.id}`"
			@toggleWatched="toggleWatched"
		/>
	</div>
</template>
<script lang="ts">
import { AuthToken, useAuthStore } from '@/stores/AuthStore'
import { useProfileStore } from '@/stores/ProfileStore'
import { computed, defineComponent, onMounted, PropType, reactive } from 'vue'
import AuctionListingItem from './AuctionListingItem.vue'
import { Auction } from '@/types/Auction'

export default defineComponent({
	components: { AuctionListingItem },
	props: {
		auctions: {
			type: Object as PropType<Array<Auction>>,
			required: true,
		},
	},
	emits: ['toggledWatched'],
	setup(props, { emit: $emit }) {
		const profileStore = useProfileStore()
		const authStore = useAuthStore()

		const state = reactive({
			authToken: null as AuthToken | null,
		})

		onMounted(() => {
			state.authToken = authStore.authToken()

			if (state.authToken) {
				profileStore.loadAccount(state.authToken)
			}
		})

		async function toggleWatched(auctionId: number, watch: boolean) {
			return
			if (!state.authToken) {
				// state.authToken = await authStore.authenticate()
			}

			if (!state.authToken) {
				return
			}

			await profileStore.watchAuction(state.authToken, auctionId, watch)
		}

		const watched = computed(() => {
			return profileStore.watched
		})

		return {
			props,
			watched,
			toggleWatched,
		}
	},
})
</script>
