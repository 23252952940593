<template>
	<modal class="z-50">
		<div class="bg-slate-50 rounded-xl drop-shadow-md overflow-hidden">
			<div class="p-4">
				<h1 class="text-2xl font-bold text-center">Authorize {{ appStore.tokenSymbol }}</h1>

				<div class="text-slate-600 space-y-4 mt-4">
					<p>
						In order to bid in BPX auctions, you need to authorize the Auction Escrow contract to transfer
						{{ appStore.tokenSymbol }} on your behalf.
					</p>

					<div>
						<p><strong>What this means</strong></p>
						<ul>
							<li>
								When you bid on a BPX auction, we can deposit your {{ appStore.tokenSymbol }} bid into
								escrow.
								<br />
								<span class="text-gray-500">
									<span class="font-semibold">Note:</span> If you are out-bid, 100% of your
									{{ appStore.tokenSymbol }} will be returned.</span
								>
							</li>
						</ul>
					</div>

					<div>
						<p>
							<strong>What this <em>does not</em> mean</strong>
						</p>

						<ul>
							<li>
								Other users/wallets <em>will not</em> have permission to transfer your
								{{ appStore.tokenSymbol }}.
							</li>
							<li>Other wallets/users <em>will not</em> have access to your other NFTs or assets.</li>
						</ul>
					</div>

					<p>
						For more information, and security best practices, read our
						<router-link to="#" class="href-primary">Security Guide</router-link>.
					</p>
				</div>
			</div>

			<div class="bg-white border-t-2 border-slate-300 mt-6 px-4 pt-6 pb-4">
				<div v-if="state.awaitingTx" class="text-center text-lg">
					<p class="text-gray-400">Awaiting Transaction Confirmation</p>
					<loader class="mx-auto" />
				</div>
				<div v-else-if="!state.complete" class="grid grid-cols-2 gap-4">
					<button class="btn-lg bg-gray-300 text-slate-600" @click.stop="cancel">Cancel</button>
					<wallet-connected>
						<template #default>
							<button class="btn-primary-lg" @click.stop="metamask.connect">
								Connect
								<p class="sub-cta">Connect to Authorize</p>
							</button>
						</template>
						<template #connected>
							<button class="btn-primary-lg" @click.stop="authorize">
								Authorize {{ appStore.tokenSymbol }}
							</button>
						</template>
					</wallet-connected>
				</div>
				<div v-else>
					<button class="btn-primary-lg block w-full" @click.stop="close">Continue</button>
				</div>
			</div>
		</div>
	</modal>
</template>
<script lang="ts">
import { useAppStore } from '@/stores/AppStore'
import { defineComponent, PropType, reactive } from 'vue'
import metamask from '@/util/metamask'
import escrow from '@/util/escrow'
import sleep from '@/util/sleep'
import { thisTypeAnnotation, tsAnyKeyword } from '@babel/types'
import { wasNotDeclined } from '@/util/Errors'
import IERC20 from '@/abi/IERC20.json'
import IAuction from '@/abi/IAuction.json'
import { ESCROW_ADDRESS, BPX_ADDRESS, UINT_MAX256 } from '@/util/escrow'
import Bugsnag from '@bugsnag/js'

export default defineComponent({
	emits: ['close', 'cancel'],
	setup(props, { emit: $emit }) {
		const appStore = useAppStore()
		const state = reactive({
			awaitingTx: false,
			txHash: null as string | null,
			complete: false,
		})

		async function authorize() {
			Bugsnag.leaveBreadcrumb('Authorize BPX Modal: authorizing bpx')
			const bpx = await metamask.loadContract(IERC20, BPX_ADDRESS)
			const tx = bpx.methods.approve(ESCROW_ADDRESS, UINT_MAX256)

			const gasEstimate = tx.estimateGas({
				from: metamask.state.wallet,
				gas: 500000,
			})

			const wait = sleep(2)
			return await tx
				.send({
					from: metamask.state.wallet,
					gas: Math.ceil(gasEstimate * 1.3),
					maxPriorityFeePerGas: null,
					maxFeePerGas: null,
				})
				.on('transactionHash', (hash) => (state.txHash = hash))
				.on('sending', () => (state.awaitingTx = true))
				.on('receipt', async (r) => {
					await wait
					state.awaitingTx = false
					state.complete = true
				})
				.catch((err) => {
					state.awaitingTx = false

					if (wasNotDeclined(err)) {
						// should add a bugsnag alert/log here
						// @todo add bugsnag alert/log
						alert('An unexpected error occurred.')
					}

					Bugsnag.notify(err)
				})
		}

		function close() {
			$emit('close')

			state.awaitingTx = false
			state.txHash = null
			state.complete = false
		}

		function cancel() {
			$emit('cancel')
		}

		return {
			props,
			state,
			metamask,
			appStore,
			authorize,
			close,
			cancel,
		}
	},
})
</script>
