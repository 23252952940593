<template>
	<template v-if="props.loading">
		<slot name="loading">
			<div class="flex justify-center items-center">
				Loading...
				<Loader />
			</div>
		</slot>
	</template>
	<template v-else>
		<slot name="default">Loaded!</slot>
	</template>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue'
import Loader from '@/components/Loader.vue'
const props = defineProps<{
	loading: boolean
}>()
</script>
