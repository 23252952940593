<template>
	<div class="bg-zinc-100 text-zinc-900">
		<Header />

		<form class="max-w-prose mx-auto px-4" @submit.prevent="handleSubmit">
			<div class="text-2xl mt-6 font-poppins">IMPORTANT: Invites are strictly limited to 1 per person.</div>

			<div class="text-lg mt-6">
				To help prevent abuse please enter your mobile number below and verify the 4-digit code you receive on
				the next page.
			</div>

			<div class="mt-2 flex w-full sm:w-3/4 mx-auto">
				<div class="py-3 px-3 text-lg bg-zinc-200 text-black rounded-l-md border-r-2 border-zinc-400">+1</div>
				<input
					type="tel"
					class="flex-grow bg-zinc-50 text-black w-full text-lg border-2 border-l-0 border-zinc-400 focus:border-teal-500 focus:ring-0 transition-colors rounded-r-md"
					placeholder="123-555-1234"
					required
					autocomplete="mobile"
					v-model="phone"
				/>
			</div>

			<div class="mt-12 text-center">
				<button
					class="py-3 px-24 bg-zinc-400 text-zinc-500 enabled:bg-teal-500 enabled:text-white transition-all hover:saturate-150 active:saturate-150 rounded-full text-xl font-poppins"
					type="submit"
					:disabled="submitting"
				>
					Next <i class="far fa-chevron-right"></i>
				</button>
			</div>
		</form>

		<Footer />
	</div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { onMounted, unref, ref } from 'vue'

import { useLeadStore } from '@/stores/LeadStore'
import api from '@/util/leadsApi'
import Footer from '@/pages/Promo/Partials/Footer.vue'
import Header from '@/pages/Promo/Partials/Header.vue'

let phone: string = null
const submitting = ref(false)

const router = useRouter()
const leadStore = useLeadStore()

onMounted(() => {
	if (unref(leadStore.id) === null) {
		router.push({ name: 'promo2.email' })
		return
	}
})

async function handleSubmit() {
	const sanitizedPhone = phone.replace(/\D+/g, '')

	try {
		submitting.value = true

		await api.updateLead(unref(leadStore.id), {
			sms_country_code: 1,
			sms_number: sanitizedPhone,
		})
		router.push({
			name: 'promo2.sms-verify',
		})
	} catch (e) {
		alert("We couldn't handle your submission. Please try again.")
	} finally {
		submitting.value = false
	}
}
</script>
