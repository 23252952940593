<template>
	<div class="bg-slate-100 rounded-lg text-lg text-gray-800 flex items-stretch self-center">
		<button
			id="show-modal"
			@click="newWalletStore.toggleBalanceModal()"
			class="rounded-l-lg border-r border-slate-300 hover:bg-slate-400"
		>
			<div class="leading-none space-x-2 py-1 px-3 flex items-baseline">
				<span><i class="fa fa-wallet text-xl" /></span>
				<span class="hidden md:block">
					<span v-if="readableBpxBalance > 0">
						{{ $format(readableBpxBalance) }}
						{{ $token }}
					</span>
					<span v-else-if="readablePendingAuctionCredit > 0">
						${{ $format(readablePendingAuctionCredit / 100) }} USD<sup>*</sup>
					</span>
					<span v-else-if="readableAuctionCredit > 0">
						${{ $format(readableAuctionCredit) }} {{ $token }}
					</span>
				</span>
			</div>
		</button>
		<!-- <router-link
			:to="{ name: 'account' }"
			class="flex items-center px-3 py-2 rounded-r-lg hover:bg-slate-400 sm:space-x-3">
				<div class="leading-none justify-center items-center hidden sm:flex">
					@{{ authStore.user.username }}
				</div>
				<div class="bg-gray-500 w-7 h-7 rounded-full flex justify-center items-center">
					<span class="fa fa-user"></span>
				</div>
		</router-link> -->

		<Menu as="div" class="p-1 relative text-left text-black rounded-r-lg hover:bg-slate-400">
			<MenuButton class="relative self-center cursor-pointer">
				<div class="flex h-10 lg:space-x-2 md:px-2">
					<div class="leading-none justify-center items-center hidden md:flex">
						@{{ authStore.user.username }}
					</div>
					<div class="h-10 w-10 rounded-full overflow-hidden flex justify-center items-center md:hidden">
						<i class="fa fa-user fa-lg">User</i>
						<!-- <img v-else src="https://www.gravatar.com/avatar/d937e8ee94a5367eb6202ed5800be365?s=250&amp;d=robohash&amp;r=pg" alt="Logged in user PFP"> -->
					</div>
				</div>

				<!-- @TODO use later
				<span v-if="hasNotifications" class="absolute right-0 top-0 block h-2 w-2 rounded-full bg-green-400 ring-2 ring-white" />
				-->
			</MenuButton>

			<transition
				enter-active-class="transition ease-out duration-100"
				enter-from-class="transform opacity-0 scale-95"
				enter-to-class="transform opacity-100 scale-100"
				leave-active-class="transition ease-in duration-75"
				leave-from-class="transform opacity-100 scale-100"
				leave-to-class="transform opacity-0 scale-95"
			>
				<MenuItems
					class="absolute right-0 z-10 mt-2 w-max-content origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-[calc(100vw-2rem)] md:w-[420px]"
				>
					<div class="px-4 py-3">
						<p v-if="!authStore.authenticated" class="whitespace-nowrap">
							No Account?
							<a
								href="https://app.blokpax.com/sign-up"
								title="Sign up to get started!"
								class="text-yellow-600 hover:underline"
								>Sign up!</a
							>
						</p>
						<p v-else class="whitespace-nowrap text-sm">Signed in as @{{ authStore.user.username }}</p>
					</div>

					<div class="py-1" v-if="authStore.authenticated">
						<MenuItem v-slot="{ active, close }">
							<form method="post" action="https://app.blokpax.com/auth/handoff">
								<input name="token" :value="accessToken" type="hidden" />
								<button
									type="submit"
									class="w-full text-left text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 hover:cursor-pointer"
									@click="
										() => {
											close()
										}
									"
								>
									<div class="w-5 h-5 inline-block">
										<i class="fas fa-candy"></i>
									</div>
									My Packs
								</button>
							</form>
						</MenuItem>
					</div>

					<div class="py-1" v-if="authStore.authenticated">
						<MenuItem v-slot="{ active, close }">
							<router-link
								v-slot="{ navigate }"
								:to="{ name: 'account' }"
								:class="[
									active ? 'bg-gray-100 text-gray-900 cursor-pointer' : 'text-gray-700',
									'block px-4 py-2 text-sm',
								]"
							>
								<div
									@click="
										() => {
											close()
											navigate()
										}
									"
								>
									<div class="w-5 h-5 inline-block">
										<i class="fas fa-rectangle-vertical-history"></i>
									</div>
									My Collection
								</div>
							</router-link>
						</MenuItem>
					</div>

					<div class="py-1">
						<MenuItem v-slot="{ active }" v-if="!authStore.authenticated">
							<span
								@click="handleLoginClicked"
								:class="[
									active ? 'bg-gray-100 text-gray-900 cursor-pointer' : 'text-gray-700',
									'block px-4 py-2 text-sm',
								]"
							>
								<div class="w-5 h-5 inline-block">
									<i class="fas fa-sign-in"></i>
								</div>
								Log In
							</span>
						</MenuItem>
						<MenuItem v-slot="{ active, close }" v-else>
							<button
								@click="
									() => {
										close()
										authStore.logout()
									}
								"
								type="submit"
								:class="[
									active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
									'block w-full px-4 py-2 text-left text-sm',
								]"
							>
								<div class="w-5 h-5 inline-block">
									<i class="fa fa-sign-out"></i>
								</div>
								Sign out
							</button>
						</MenuItem>
					</div>
				</MenuItems>
			</transition>
		</Menu>

		<DepositModal v-if="newWalletStore.balanceModalVisible" />
	</div>
</template>
<script lang="ts" setup>
import { useAuthStore } from '@/stores/AuthStore'
import { useNewWalletStore } from '@/stores/NewWalletStore'
import { defineComponent, PropType, computed, onMounted, reactive, watch, ref } from 'vue'
import DepositModal from './DepositModal.vue'
import { BN } from 'bn.js'
import Currency from '@/types/Currency'
import { useRoute } from 'vue-router'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

const authStore = useAuthStore()
const newWalletStore = useNewWalletStore()
const route = useRoute()

const readableBpxBalance = computed(() => {
	return new Currency(
		newWalletStore.balances.reduce(function (bnBalance, bal) {
			if (bal.slug == 'bpx' || bal.slug == 'vbpx') {
				return bnBalance.add(new BN(bal.available))
			}

			return bnBalance
		}, new BN('0'))
	).toDecimal()
})

const readablePendingAuctionCredit = computed(() => {
	return newWalletStore.balances.find((b) => b.slug == 'usd-auction-credit')?.available ?? 0
})

const readableAuctionCredit = computed(() => {
	return new Currency(
		newWalletStore.balances.find((b) => b.slug == 'auction-credit')?.available ?? new BN('0')
	).toDecimal()
})

async function handleLoginClicked() {
	authStore.redirectToLogin(route.path)
}

const accessToken = computed(() => {
	if (!authStore.authenticated) {
		return null
	}
	return authStore.token?.data.accessToken
})
</script>
