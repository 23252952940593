import { defineStore } from 'pinia'
import api from '@/util/api'
import { Storefront } from '@/types/Storefront'
import { Asset, AssetList, Listing, NewListing } from '@/types/Asset'
import _ from 'lodash'
import axios from 'axios'
// import { URLS } from '@/util/constants';
import { useExchangeStore } from '@/stores/ExchangeStore'
import { useAuthStore } from './AuthStore'

type AssetMap = Record<string, Asset>
declare function emptyObject<T>(): T

export const useAssetStore = defineStore('assets', {
	state: () => ({
		asset: {},
		listing: {},
		bpxPrice: null,
		activityDataAsset: {},
		AssetMap: {
			assetMap: {} as Asset, //emptyObject<Asset>(),
		},
		ownedQuantity: 0,
		//ownsAsset = ownedQuantity ? +ownedQuantity > 0 : false
	}),
	getters: {
		getAssetList: (state) => state.AssetMap,
		getAssetById: (state) => (id: string) =>
			state.AssetMap[id] || {
				id,
				storefront_id: '',
				metadata: { id: '', image: '', attributes: [{ trait_type: '', value: '' }] },
			},
	},
	actions: {
		setAssetList(payload) {
			this.assetMap = Object.assign(this.tokenMap, payload)
		},
		async fetchAssetList(slug: string) {
			const assetList = await api.loadAsset(slug, 0).catch((err) => {
				console.error(err.message)
			})

			const assetMap = Object.fromEntries(
				_.map(assetList, ({ id, storefront, metadata }) => [id, { id, storefront, metadata }])
			)

			this.setAssetList(assetMap)
			return assetMap
		},

		async setActivityDataAsset(obj: Object) {
			this.state.activityDataAsset = obj
		},

		async createListing(listing: NewListing): Promise<Listing | null> {
			const authStore = useAuthStore()
			const newListing = await api.createListing(listing, authStore.token.data.accessToken)

			if (newListing) {
				// yay listing is made
				return newListing
			}

			return null
		},

		async editListing(id: string, listing: NewListing): Promise<Listing | null> {
			const authStore = useAuthStore()
			const newListing = await api.editListing(id, listing, authStore.token.data.accessToken)

			if (newListing) {
				// yay listing is made
				return newListing
			}

			return null
		},

		async deleteListing(id: string): Promise<boolean> {
			const authStore = useAuthStore()
			return await api.deleteListing(id, authStore.token.data.accessToken)
		},

		async loadActivity(
			storefrontSlug: string,
			assetId: string,
			page: number
		): Promise<{ data: Object; meta: Object }> {
			return api.loadAssetActivity(storefrontSlug, assetId, page)
		},
	},
})
