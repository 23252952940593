const BASE_URL = import.meta.env.VITE_LEADS_API_BASE_URL

interface Error {
	success: false
	code: string
	error: string
}

async function handleEmptyResponse(pr: Promise<Response>): Promise<any> {
	return pr
		.then((res) => {
			if (res.status >= 200 && res.status < 300) {
				return res
			} else {
				return res.json().then((json) => Promise.reject(json))
			}
		})
		.catch((e) => {
			throw e
		})
}

async function handleResponse(pr: Promise<Response>): Promise<any> {
	return pr
		.then((res) => {
			if (res.status >= 200 && res.status < 300) {
				return res
			} else {
				return res.json().then((json) => Promise.reject(json))
			}
		})
		.then((res) => res.json())
		.catch((e) => {
			throw e
		})
}

const defaultHeaders = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
}

interface Lead {
	id: string
}

async function createLead(body: any): Promise<Lead> {
	const resp = await handleResponse(
		fetch(BASE_URL + '/api/lead/', {
			method: 'POST',
			headers: {
				...defaultHeaders,
			},
			body: JSON.stringify(body),
		})
	)

	return resp
}

async function updateLead(id: string, body: any) {
	const resp = await handleEmptyResponse(
		fetch(BASE_URL + '/api/lead/' + id, {
			method: 'POST',
			headers: {
				...defaultHeaders,
			},
			body: JSON.stringify(body),
		})
	)

	return resp
}

async function confirmSMS(id: string, body: any) {
	const resp = await handleEmptyResponse(
		fetch(BASE_URL + '/api/lead/' + id + '/confirm-sms', {
			method: 'POST',
			headers: {
				...defaultHeaders,
			},
			body: JSON.stringify(body),
		})
	)

	return resp
}

interface OrderResponseOrder {
	formatted_public_id: string
	total_amount: number
}

interface OrderResponse {
	order: OrderResponseOrder
	redirect: string
}

async function createOrder(body: any): Promise<OrderResponse> {
	const resp = await handleResponse(
		fetch('/api/create-order', {
			method: 'POST',
			headers: {
				...defaultHeaders,
			},
			body: JSON.stringify(body),
		})
	)

	return resp
}

const api = {
	createLead,
	updateLead,
	confirmSMS,
	createOrder,
}

export default api
