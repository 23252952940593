<template>
	<div class="bg-zinc-100 text-zinc-900">
		<Header />

		<div class="max-w-prose mx-auto px-4">
			<div class="text-3xl font-poppins font-bold text-center mt-6">
				HERE IS THE MOST
				<span class="text-teal-500">&OpenCurlyDoubleQuote;NO-BRAINER OFFER&CloseCurlyDoubleQuote;</span> YOU
				WILL SEE THIS YEAR:
			</div>

			<div class="text-2xl font-normal font-poppins sm:text-center mt-6">
				Click
				<span class="text-[red] font-black rounded-lg" type="button">PLAY</span> below and see it to believe it.
			</div>

			<iframe
				src="https://www.youtube-nocookie.com/embed/YKPSN3qPqMo?si=y_1smXxOBk_Wi0pC&rel=0&autoplay=1"
				title="YouTube video player"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				class="w-full md:w-[120%] md:-mx-[10%] aspect-video sm:rounded-lg mt-12"
				allowfullscreen
			></iframe>

			<div class="text-3xl mt-6 font-poppins text-teal-500 font-bold">In case you missed it... 🧐</div>

			<!-- <p class="text-2xl/relaxed bg-zinc-50 p-4 rounded-xl font-poppins mt-6">
				<span class="text-white bg-teal-500 rounded p-1">Each pack is $7.25</span>, which is more than
				<span class="underline decoration-red-600 decoration-2 italic">50% off</span> the suggested retail
				price!
			</p> -->

			<p class="text-2xl font-poppins mt-6">
				<span class="font-bold uppercase">Get all this</span> with your first pack order:
			</p>

			<div class="grid sm:w-4/5 mx-auto">
				<div class="text-xl bg-zinc-50 my-6 rounded-lg border-zinc-400/50 border-b-2">
					<div class="h-32 grid grid-cols-[8rem,1fr] border-b border-b-zinc-400/50">
						<div class="place-self-center">
							<i class="fas fa-cards-blank text-4xl text-sky-700"></i>
						</div>
						<div class="pr-4 w-full self-center">
							<strong>1 card per pack</strong> &ndash; only
							<span class="bg-teal-500 p-1 rounded-md text-white font-bold whitespace-nowrap"
								>$7.25 per pack</span
							>
							(over 50% off retail!)
						</div>
					</div>

					<div class="h-32 grid grid-cols-[8rem,1fr]">
						<div class="place-self-center">
							<i class="fas fa-gavel text-4xl text-amber-800"></i>
						</div>
						<div class="pr-4 w-full self-center">
							<strong>Dollar-for-dollar MATCHING Credit</strong> (up to $500) to bid on rare graded
							collectibles
						</div>
					</div>
				</div>
			</div>

			<p class="text-2xl font-poppins mt-6">
				<span class="font-bold uppercase">Plus</span>, grab
				<span class="underline underline-offset-4 decoration-teal-500">at least 20 packs</span> and we will
				throw in:
			</p>

			<div class="grid sm:w-4/5 mx-auto">
				<div class="text-xl bg-zinc-50 my-6 rounded-lg border-zinc-400/50 border-b-2">
					<div class="h-32 grid grid-cols-[8rem,1fr] border-b border-b-zinc-400/50">
						<div class="place-self-center">
							<i class="fas fa-burst text-4xl text-fuchsia-500"></i>
						</div>
						<div class="pr-4 w-full self-center">
							<strong>One ultra-rare Shatter Pack</strong> (median price of $100 on the secondary market!)
						</div>
					</div>

					<div class="h-32 grid grid-cols-[8rem,1fr]">
						<div class="place-self-center">
							<i class="fas fa-money-bill-1-wave text-4xl text-emerald-600"></i>
						</div>
						<div class="pr-4 w-full self-center">
							<strong>$25 credit to</strong> get you started in our vibrant secondary market!
						</div>
					</div>
				</div>
			</div>

			<div class="text-3xl mt-6 font-poppins text-teal-500 font-bold">Don't just take our word for it... 😊</div>

			<div class="space-y-6 mt-6">
				<tweet
					user="bjamps"
					username="@bjamps"
					:verified="true"
					avatar="/image/testimonial/bjamps-200x.webp"
					text="I have won well into the six figures, in value of physical cards from Blokpax, all as rewards just for collecting what I love."
					link="https://twitter.com/bjamps/status/1704352128309903497"
					:date="new Date('2023-09-20T04:29:47.000Z')"
				/>

				<tweet
					user="Phisigfsu"
					username="@phisigfsu"
					:verified="true"
					avatar="/image/testimonial/phisigfsu-200x.webp"
					text="I won this grail in a free drop...along with a PSA 6 that I later auctioned. I have no idea of the
				exact value I've extracted in physical collectibles, but it has to be well over $20,000. I have stacks
				of physical cards and wax from BPX auctions."
					link="https://twitter.com/phisigfsu/status/1704314619089564129"
					:media="['https://pbs.twimg.com/media/F6bxHWKWEAAhhX4?format=webp&name=900x900']"
					:date="new Date('2023-09-20T02:00:44.000Z')"
				/>

				<tweet
					user="wrencher412"
					username="@wrencher412"
					:verified="false"
					avatar="/image/testimonial/wrencher412-200x.webp"
					text="Easily $10k plus of physical graded slabs redeemed! There's so many different ways to come out ev+. As a physical card collector I couldn’t pass up spending my reward miles on graded cards."
					link="https://twitter.com/wrencher412/status/1704316858868863345"
					:media="[
						'https://pbs.twimg.com/media/F6bzJf4XIAAhHQj?format=jpg&name=900x900',
						'https://pbs.twimg.com/media/F6bzJf1XsAAZGx9?format=jpg&name=900x900',
						'https://pbs.twimg.com/media/F6bzJf1WYAA1bbO?format=jpg&name=medium',
						'https://pbs.twimg.com/media/F6bzJf7XsAA59n2?format=jpg&name=medium',
					]"
					:date="new Date('2023-09-20T02:09:38.000Z')"
				/>
			</div>

			<div class="text-3xl mt-12 font-poppins text-teal-500 font-bold">So, are you convinced? 😉</div>

			<div class="grid gap-4 mt-6">
				<div class="bg-zinc-700 text-white overflow-hidden px-4 py-8 sm:w-3/4 mx-auto rounded-xl">
					<div>
						<div class="mb-2 font-poppins text-3xl font-medium">Football 2023</div>
						<img src="/image/football-presale-400x.webp" class="block w-full rounded-md" />
					</div>

					<ul class="mt-8 font-poppins text-lg list-disc pl-8">
						<li>
							Only $7.25 per pack! (<span class="line-through decoration-red-600 decoration-1"
								>$15.00 MSRP</span
							>)
						</li>
						<li>1 random card per pack</li>
					</ul>

					<div class="mt-8 font-poppins text-lg text-center">How many packs do you want?</div>
					<div class="max-w-xs mx-auto">
						<quantity-selector :max="120" :step="1" :default="footballPacks" v-model="footballPacks" />
					</div>
					<div class="mt-6 text-sm text-center">
						** 20 packs (or more) gets a FREE Shatter Pack.<br />Guaranteed /10 or better hit.
					</div>
				</div>
			</div>

			<div
				class="bg-zinc-50 grid grid-cols-[3fr,1fr] p-4 gap-4 rounded-xl shadow-md mt-8 sm:text-lg items-center"
			>
				<div class="font-poppins text-base sm:text-xl font-medium text-left">You get</div>
				<div class="font-poppins text-base sm:text-xl font-medium text-center">You pay</div>

				<div>
					<strong>{{ footballPacks }}</strong> packs of <strong>Home Team Heroes: Football 2023</strong>
				</div>
				<div class="text-center text-xl">
					<span class="line-through decoration-red-600 decoration-2">
						${{ currencyFormat(footballPacks * 15) }}</span
					><br />${{ currencyFormat(total) }}
				</div>

				<div>
					<strong>${{ currencyFormat(Math.min(500, footballPacks * FOOTBALL_PACK_PRICE)) }}</strong> auction
					credit to bid on rare graded collectibles
				</div>
				<div class="text-center text-xl">
					<span class="line-through decoration-red-600 decoration-2"
						>${{ currencyFormat(Math.min(500, footballPacks * FOOTBALL_PACK_PRICE)) }}</span
					><br />${{ 0 }}
				</div>

				<div v-if="footballPacks >= 20">One ultra-rare <strong>Shatter Pack</strong></div>
				<div class="text-center text-xl" v-if="footballPacks >= 20">
					<span class="line-through decoration-red-600 decoration-2">$100</span><br />${{ 0 }}
				</div>

				<div v-if="footballPacks > 0"><strong>$25 Marketplace Credit</strong></div>
				<div class="text-center text-xl" v-if="footballPacks > 0">
					<span class="line-through decoration-red-600 decoration-2">$25</span><br />${{ 0 }}
				</div>

				<div class="text-2xl font-bold pt-4 border-t border-t-zinc-400 h-24 font-poppins">Your Total</div>
				<div class="text-2xl text-center pt-4 border-t border-t-zinc-400 h-24">
					<span class="line-through decoration-red-600 decoration-2"
						>${{
							currencyFormat(
								footballPacks * 15 +
									Math.min(500, footballPacks * FOOTBALL_PACK_PRICE) +
									(footballPacks >= 20 ? 100 : 0) +
									25
							)
						}}</span
					><br />
					<span class="font-bold">${{ currencyFormat(total) }}</span>
				</div>
			</div>

			<div class="grid gap-x-4 gap-y-8 my-12 items-center">
				<div class="text-center">
					<button
						@click="handleSubmit"
						:disabled="submitting || footballPacks == 0"
						class="bg-zinc-400 text-zinc-700 cursor-not-allowed enabled:text-zinc-50 enabled:bg-teal-500 enabled:cursor-pointer font-poppins text-xl font-medium enabled:hover:saturate-150 transition-all align-middle py-4 px-24 rounded-full enabled:shadow-lg enabled:shadow-black/25 enabled:hover:-translate-y-1 enabled:hover:shadow-black/30 enabled:active:translate-y-0 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-teal-700"
					>
						Buy Now
						<span class="inline-block align-middle text-lg/7">
							<i class="far fa-chevron-right"></i>
						</span>
					</button>
				</div>
			</div>
		</div>

		<Footer />
	</div>
</template>

<script lang="ts" setup>
import { ComputedRef, Ref, computed, ref, unref, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import currencyFormat from '@/util/currencyFormat'
import { useLeadStore } from '@/stores/LeadStore'
import Footer from '@/pages/Promo/Partials/Footer.vue'
import Header from '@/pages/Promo/Partials/Header.vue'
import QuantitySelector from '@/pages/Promo/Partials/QuantitySelector.vue'
import Tweet from './Partials/Tweet.vue'
import api from '@/util/leadsApi'

const router = useRouter()
const leadStore = useLeadStore()

const FOOTBALL_PACK_PRICE = 7.25

const footballPacks: Ref<number> = ref(4)
const submitting = ref(false)

onMounted(() => {
	if (unref(leadStore.id) === null) {
		router.push({ name: 'promo.email' })
		return
	}
})

const total: ComputedRef<number> = computed(() => {
	return footballPacks.value * FOOTBALL_PACK_PRICE
})

async function handleSubmit() {
	const body = {
		ref: 'blokpax.com/promo',
		subref: unref(leadStore.id),
		items: [{ id: '23_NATIONAL_FOOTBALL_1', quantity: footballPacks.value }],
	}

	try {
		submitting.value = true

		const resp = await api.createOrder(body)

		top.location.href = resp.redirect
	} catch (e) {
		alert("We couldn't process your submission. Try again.")
	} finally {
		submitting.value = false
	}
}
</script>
