<template>
	<div class="mt-3">
		<h3 class="font-semibold">Auctions</h3>
		<ul class="ml-2 space-y-3 mt-2">
			<li
				class="cursor-pointer"
				v-for="filter in auctionFilterStore.allFilters"
				@click.stop="toggleFilter(filter.id)"
			>
				<div class="flex gap-x-2 items-center">
					<div
						class="flex items-center justify-center w-8 flex-grow-0 flex-shrink-0 aspect-square border-2 rounded-lg transition-colors"
						:class="
							auctionFilterStore.modified.get(filter.id) ?? false
								? ['bg-sky-100', 'text-sky-600', 'border-sky-600']
								: ['border-slate-300']
						"
					>
						<span v-show="auctionFilterStore.modified.get(filter.id) ?? false"
							><i class="fa fa-check"></i
						></span>
					</div>
					<div class="flex-grow flex justify-between items-center">
						<p>{{ filter.name }}</p>
						<p class="text-slate-500 font-light">
							{{ counts[filter.id] ?? '' }}
						</p>
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, watch, ref } from 'vue'
import { Tag, Taxonomy } from '@/types/Taxonomy'
import { useTaxonomyStore } from '@/stores/TaxonomyStore'
import { useAuctionStore } from '@/stores/AuctionStore'
import Auction, { AuctionGroup } from '@/types/Auction'
import { useAuctionFilterStore } from '@/stores/AuctionFilterStore'
import auction from '@/util/auction'
import { useAuthStore } from '@/stores/AuthStore'
import metamask from '@/util/metamask'
import { DateTime } from 'ts-luxon'

export default defineComponent({
	props: {
		group: {
			type: Object as PropType<AuctionGroup>,
			required: true,
		},
	},
	setup(props) {
		const auctionFilterStore = useAuctionFilterStore()
		const auctionStore = useAuctionStore()

		function toggleFilter(filter: string) {
			auctionFilterStore.toggleModifiedFilter(filter)
		}

		const counts = ref({})
		setInterval(() => {
			const auctions =
				auctionStore.auctions?.filter((auction) => auction.auction_group.id === props.group.id) ?? []

			const tmp = auctionFilterStore.allFilters.reduce((c, filter) => {
				c[filter.id] = auctions.filter((a) =>
					filter.passes({
						auction: a,
						user: metamask.state.wallet,
					})
				).length
				return c
			}, {})

			for (const k in tmp) {
				if (tmp[k] != counts.value[k]) {
					counts.value = tmp
					return
				}
			}
		}, 1000)

		watch(() => counts.value, auctionFilterStore.forceRefresh)

		return {
			props,
			auctionFilterStore,
			counts,
			toggleFilter,
		}
	},
})
</script>
