import { defineStore } from 'pinia'
import { Ref, computed, ref } from 'vue'

export const useLeadStore = defineStore('lead', () => {
	const id: Ref<string | null> = ref(localStorage.getItem('lead:id'))

	function setID(s: string | null) {
		if (s === null) {
			localStorage.removeItem('lead:id')
			id.value = null
			return
		}

		localStorage.setItem('lead:id', s)
		id.value = s
	}

	return {
		id,
		setID,
	}
})
