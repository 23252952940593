import { defineStore } from 'pinia'
import api from '@/util/api'
import { ExchangeRate } from '@/types/Currency'
import { BN } from 'bn.js'
import { ref, reactive } from 'vue'

export const useExchangeStore = defineStore('exchange', () => {
	let pollInterval
	let pollPromise
	const exchangeRate = ref<ExchangeRate | null>(null)

	async function monitorExchangeRate(force: boolean = false) {
		if (pollInterval && false == force) {
			return await pollPromise
		}

		if (pollInterval) {
			clearInterval(pollInterval)
		}

		pollInterval = setInterval(pollExchangeRate, 60 * 1000)
		return await pollExchangeRate()
	}

	async function pollExchangeRate() {
		pollPromise = api.getExchangeRate()
		const rate = await pollPromise
		if (rate) {
			exchangeRate.value = rate
		}
	}

	async function usd2bpx(usd: number | string): Promise<ExchangeRate> {
		return await api.bpxVal(usd)
	}

	async function bpx2usd(bpx: string, isDec: boolean = false): Promise<ExchangeRate> {
		return await api.usdVal(bpx, isDec)
	}

	async function usd(amt: string, isDec: boolean = false) {
		if (!exchangeRate.value) {
			await monitorExchangeRate()
		}

		if (Number(amt) == 0) {
			return 0
		}

		let bpxAmt = new BN(amt)
		if (isDec) {
			// convert 100.1234 bpx to 1001234
			bpxAmt = bpxAmt.muln(1e9)
		}

		// usd amount to 9 decimals, as an integer
		let conv = bpxAmt.muln(Math.floor(exchangeRate.value.exchangeRate * 1e5))

		// convert usd down to 2 decimals, as an integer
		// account for the precision shift from the exchange rate to integer
		// 9 decimals of bpx + 5 decimals from exchange rate - 2 decimals (because echange rate is dollars per bpx,
		// . and we want the final number to be pennies)
		const exponent = (new BN(10)).pow(new BN(12))
		return  Math.floor(conv.div(exponent).toNumber())
	}

	/**
	 * convert usd to bpx
	 * @param usdAmt in dollars (todo: update this to cents, since we use cents everywhere..)
	 * @returns bpxAmount in bigint form as string (no floats)
	 */
	async function bpx(usdAmt: string) : string {
		if (!exchangeRate.value) {
			await monitorExchangeRate()
		}

		// convert usd
		let bpxAmt = Number(usdAmt) / (exchangeRate.value.exchangeRate * 100) // convert to cents per bpx
		// convert decimal bpx (1234.123123123123) to 4-decimal precision (1234.1234) then to full bpx amount (1234123400000)
		bpxAmt = Math.floor(bpxAmt * 1e4) * 1e5
		return String(bpxAmt)
	}

	return {
		usd,
		bpx,
	}
})
