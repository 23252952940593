<template>
	<div
		class="flex items-center text-sm overflow-hidden text-slate-500 space-x-2 px-4 py-2 bg-slate-100 border-b-2 border-slate-200"
	>
		<div class="inline-block -mr-1 sm:-mb-1">
			<img src="/image/bpx-logo@2000.png" class="w-6 rounded opacity-50" />
		</div>
		<router-link :to="{ name: 'auction-index' }" class="whitespace-nowrap">Auctions</router-link>

		<template v-if="props.group">
			<span class="text-slate-300">/</span>
			<router-link
				:to="{ name: 'auction-listings', params: { group: props.group.slug } }"
				class="whitespace-nowrap"
			>
				{{ props.group.name }}
			</router-link>
		</template>

		<template v-if="props.auction">
			<span class="text-slate-300">/</span>
			<router-link
				:to="{
					name: 'auction',
					params: { group: props.auction.auction_group.slug, auction: props.auction.id },
				}"
				class="whitespace-nowrap overflow-ellipsis overflow-hidden"
				:title="`Lot #${props.auction.id} - ${props.auction.title}`"
				>{{ `Lot #${props.auction.id} - ${props.auction.title}` }}
			</router-link>
		</template>
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
	props: {
		group: {
			required: false,
			default: () => false,
		},
		auction: {
			required: false,
			default: () => false,
		},
	},
	setup(props) {
		return {
			props,
		}
	},
})
</script>
