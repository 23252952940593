<template>
	<div>
		<div class="fixed inset-0 z-10 md:sticky md:h-[calc(100vh-12rem)] top-0 flex flex-col overflow-hidden">
			<div
				class="flex justify-between items-center flex-shrink-0 bg-gray-50 px-4 py-2 drop-shadow-md border-b-2 border-slate-200"
			>
				<h2 class="text-xl font-semibold">Filters</h2>
				<button
					class="md:hidden text px-3 aspect-square border-2 border-slate-200 rounded-xl text-slate-600 hover:border-sky-600 hover:text-sky-600"
					@click.stop="close"
				>
					<i class="fa fa-close"></i>
				</button>
			</div>

			<div class="flex-grow overflow-y-auto space-y-4 px-4 py-2 bg-gray-50 drop-shadow-md">
				<auction-filter-group :group="props.group" />

				<div
					v-if="taxonomyStore.loaded"
					v-for="category in categories"
					:key="`taxonomy-category-${category.id}`"
				>
					<filter-group v-if="hasAuctions[category.id]" :group="props.group" :category="category" />
				</div>
			</div>

			<div
				class="flex-shrink-0 bg-gray-50 px-4 pt-4 pb-3 border-t-2 border-slate-200 drop-shadow-md flex justify-center items-center space-x-4"
			>
				<div class="flex-auto">
					<button
						class="block w-full text-center py-2 bg-gray-300 text-slate-600 rounded-md drop-shadow-md"
						@click.stop="resetFilters"
					>
						Reset Filters
					</button>
				</div>
				<div class="flex-auto">
					<button
						class="block w-full text-center py-2 bg-sky-800 text-sky-100 rounded-md drop-shadow-md"
						@click.stop="applyFilters"
					>
						Apply Filters
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType, onMounted, onUnmounted, computed, onActivated, onDeactivated } from 'vue'
import FilterGroup from './FilterGroup.vue'
import AuctionFilterGroup from './AuctionFilterGroup.vue'
import { useTaxonomyStore } from '@/stores/TaxonomyStore'
import { useAuctionStore } from '@/stores/AuctionStore'
import { useAuctionFilterStore } from '@/stores/AuctionFilterStore'
import { AuctionGroup } from '@/types/Auction'

export default defineComponent({
	components: { FilterGroup, AuctionFilterGroup },
	emits: ['close'],
	props: {
		group: {
			type: Object as PropType<AuctionGroup>,
			required: true,
		},
		tags: {
			type: Object as PropType<Array<number> | null>,
			required: false,
			default: () => null,
		},
	},
	setup(props, { emit: $emit }) {
		const taxonomyStore = useTaxonomyStore()
		const auctionFilterStore = useAuctionFilterStore()
		const auctionStore = useAuctionStore()

		function resetFilters() {
			taxonomyStore.resetFilters()
			auctionFilterStore.resetFilters()
			close()
		}

		function applyFilters() {
			taxonomyStore.applyFilters()
			auctionFilterStore.applyFilters()
			close()
		}

		function close() {
			$emit('close')
		}

		const categories = computed(() => {
			if (null == props.tags) {
				return taxonomyStore.taxonomy
			}

			return taxonomyStore.taxonomy
				?.map((taxonomy) => {
					const tagIds = taxonomy.tags.map((t) => t.id)
					const useTags = tagIds.filter((id) => props.tags.has(id))

					if (useTags.length) {
						return {
							...taxonomy,
							tags: taxonomy.tags.filter((t) => useTags.indexOf(t.id) !== -1),
						}
					}

					return null
				})
				.filter((t) => null !== t)
		})

		const hasAuctions = computed(() => {
			let hasAuctions = {}
			taxonomyStore.taxonomy?.map((category) => {
				let catHasAuctions = false
				category.tags.map((tag) => {
					catHasAuctions = catHasAuctions || auctionStore.tagMap.has(tag.id)
				})

				hasAuctions[category.id] = catHasAuctions
			})

			return hasAuctions
		})

		onActivated(() => {
			document.body.classList.add('overflow-hidden')
		})

		onDeactivated(() => {
			document.body.classList.remove('overflow-hidden')
		})

		return {
			props,
			taxonomyStore,
			resetFilters,
			applyFilters,
			close,
			hasAuctions,
			categories,
		}
	},
})
</script>
