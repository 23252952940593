export function keyDuration(duration: Object[]): Object {
	return duration.reduce((a, u) => {
		a[u.unitKey] = u
		return a
	}, {})
}
export function makeLabel(duration: Object[]): { val: string; label: string } {
	const keyedDuration = keyDuration(duration)
	for (const unit of ['Days', 'Hours', 'Minutes', 'Seconds']) {
		const key = unit.toLowerCase()

		if (keyedDuration[key].val > 0 || key == 'seconds') {
			const val = keyedDuration[key].val
			let label = keyedDuration[key].label

			return { val, label: label.toLowerCase() }
		}
	}
}
