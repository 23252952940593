<template>
	<div v-if="null !== actionComponent" class="flex justify-start items-center space-x-2">
		<p><i :class="`${actionComponent.icon} w-6 h-6`"></i></p>
		<p>{{ actionComponent.text }}</p>
	</div>
</template>
<script lang="ts" setup>
import { defineProps, computed } from 'vue'
import * as Activity from '@/types/Activity'

const props = defineProps<{
	action: String
}>()

const actionComponent: { icon: string; text: string } | null = computed(() => {
	return (
		{
			[Activity.TRANSFER]: {
				icon: 'fa-light fa-paper-plane',
				text: 'Transfer',
			},
			[Activity.TRANSFER_RECEIVED]: {
				icon: 'fa-light fa-paper-plane',
				text: 'Received',
			},
			[Activity.TRANSFER_SENT]: {
				icon: 'fa-light fa-paper-plane',
				text: 'Sent',
			},
			[Activity.AUCTION_WON]: {
				icon: 'fa-light fa-gavel',
				text: 'Won Auction',
			},
			[Activity.AUCTION_SOLD]: {
				icon: 'fa-light fa-cash-register',
				text: 'Sold Auction',
			},
			[Activity.AUCTION_EXPIRED]: {
				icon: 'fa-light fa-calendar-xmark',
				text: 'Auction Expired',
			},
			[Activity.AUCTION_OUTBID]: {
				icon: 'fa-light fa-gavel',
				text: 'Outbid',
			},
			[Activity.AUCTION_BID]: {
				icon: 'fa-light fa-hand',
				text: 'Bid',
			},
			[Activity.AUCTION_ENDED]: {
				icon: 'fa-light fa-cash-register',
				text: 'Auction Ended',
			},
			[Activity.LISTING_PURCHASED]: {
				icon: 'fa-regular fa-bag-shopping',
				text: 'Purchased',
			},
			[Activity.LISTING_CREATED]: {
				icon: 'fa-light fa-shop',
				text: 'Created Listing',
			},
			[Activity.LISTING_CANCELLED]: {
				icon: 'fa-regular fa-ban',
				text: 'Cancelled Listing',
			},
			[Activity.LISTING_SOLD]: {
				icon: 'fa-light fa-cash-register',
				text: 'Sold',
			},
		}[props.action] || null
	)
})
</script>
