<template>
	<div class="bg-zinc-100 text-zinc-900">
		<Header />

		<form class="max-w-prose mx-auto px-4" @submit.prevent="handleSubmit">
			<div class="text-3xl font-bold font-poppins sm:text-center">
				<span class="text-[red] rounded-lg">FREE</span> Break Spots - Bounties up to $50,000!
			</div>

			<div class="text-2xl font-normal font-poppins sm:text-center mt-6">
				Click play to learn more &amp; sign up now below.
			</div>

			<iframe
				src="https://www.youtube-nocookie.com/embed/uOxV9_TCovU?si=WKyCNZG3v2MQRLfq&rel=0&autoplay=1"
				title="YouTube video player"
				frameborder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				allowfullscreen
				class="w-full md:w-[120%] md:-mx-[10%] aspect-video sm:rounded-lg mt-12"
			></iframe>

			<br />

			<div class="bg-teal-200 text-zinc-900 space-y-2 mt-4 text-sm p-4 rounded-lg">
				<p><span class="w-4 text-center inline-block">-</span> 400 Pack Break</p>
				<p>
					<span class="w-4 text-center inline-block">-</span>
					Streamed live on YouTube
				</p>
				<p>
					<span class="w-4 text-center inline-block">-</span>
					Allocation of free spots. Hits randomed to audience.
				</p>
			</div>

			<div class="mt-12 text-lg font-poppins text-center">Drop your email below for an invite.</div>

			<div class="mt-6 text-center">
				<input
					type="email"
					class="bg-zinc-50 text-black w-full sm:w-3/4 mx-auto text-lg border-2 border-zinc-400 rounded-md focus:border-teal-500 focus:border-2 focus:ring-0 transition-colors"
					placeholder="john.doe@blokpax.com"
					autocapitalize="off"
					autocomplete="email"
					required
					v-model="email"
				/>
			</div>

			<div class="mt-12 text-center">
				<button
					class="py-3 px-24 bg-zinc-400 text-zinc-500 enabled:bg-teal-500 enabled:text-white transition-all hover:saturate-150 active:saturate-150 rounded-full text-xl font-poppins"
					type="submit"
					:disabled="submitting"
				>
					Next <i class="far fa-chevron-right"></i>
				</button>
			</div>
		</form>

		<Footer />
	</div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'

import { useLeadStore } from '@/stores/LeadStore'
import api from '@/util/leadsApi'
import Footer from '@/pages/Promo/Partials/Footer.vue'
import Header from '@/pages/Promo/Partials/Header.vue'
import { onMounted, unref, ref } from 'vue'

let email: string = null
const submitting = ref(false)

const router = useRouter()
const leadStore = useLeadStore()

onMounted(() => {
	if (unref(leadStore.id) !== null) {
		router.push({
			name: 'promo2.thank-you',
		})
	}
})

async function handleSubmit() {
	try {
		submitting.value = true

		const resp = await api.createLead({
			email,
			ref: 'promo2',
		})

		leadStore.setID(resp.id)

		router.push({
			name: 'promo2.phone',
		})
	} catch (e) {
		alert("We couldn't handle your submission. Please try again.")
	} finally {
		submitting.value = false
	}
}
</script>
