<template>
	<div class="m-4">
		<NewActivityTable :data-loader="loadActivity" />
	</div>
</template>
<script lang="ts" setup>
import { defineComponent, PropType } from 'vue'
import NewActivityTable from '@/components/NewActivityTable.vue'
import { useStorefrontStore } from '@/stores/StorefrontStore'
const storeStore = useStorefrontStore()

async function loadActivity(page: number | null = null): Promise<{ data: Object; meta: Object }> {
	return storeStore.loadActivity(storeStore.state.storefront.slug, page)
}
</script>
