<template>
	<div class="w-full">
		<h2 class="font-bold text-2xl text-center md:text-left">Your Wallet Balance</h2>
		<div class="space-y-4">
			<!-- bpx reward miles -->
			<div class="md:flex justify-between mt-8 text-xl px-4 rounded-xl">
				<p class="text-center md:text-left font-medium">BPX Reward Miles</p>
				<p
					class="hidden md:block border-b border-dotted border-gray-300 flex-grow flex-shrink mx-4 self-center"
				></p>
				<p class="text-center md:text-right text-gray-600">
					{{ $format(bpxBalance.toDecimal()) }}
					{{ $token }}
				</p>
			</div>

			<!-- store credit -->
			<div class="md:flex justify-between items-center text-xl px-4 rounded-xl">
				<p class="text-center md:text-left font-medium">Store Credit</p>
				<p
					class="hidden md:block border-b border-dotted border-gray-300 flex-grow flex-shrink mx-4 self-center"
				></p>
				<p v-if="storeCredit && storeCredit.available > 0" class="text-center md:text-right text-gray-600">
					${{ $format(storeCredit.available / 100) }}
				</p>
				<router-link
					v-else
					:to="{ name: 'buy-store-credit' }"
					class="href-primary font-sans text-base"
					@click="newWalletStore.toggleBalanceModal()"
				>
					Deposit Store Credit
				</router-link>
			</div>

			<div v-if="pendingAuctionCredit && pendingAuctionCredit.available > 0" class="px-4 mt-4">
				<!-- pending auction credit -->
				<div class="md:flex justify-between text-xl rounded-xl">
					<p class="font-medium text-center md:text-left">Pending Auction Credit</p>
					<p
						class="hidden md:block border-b border-dotted border-gray-300 flex-grow flex-shrink mx-4 self-center"
					></p>
					<p class="text-center md:text-right text-gray-600">
						${{ $format(pendingAuctionCredit.available / 100) }}
					</p>
				</div>
				<p class="mt-2 text-center text-xs md:text-right text-gray-500 md:text-sm">
					Pending Auction Credit will be usable beginning in mid-October 2023.
				</p>
			</div>

			<div class="mt-4">
				<router-link
					:to="{ name: 'buy-store-credit' }"
					class="btn-primary-lg block w-full"
					@click="newWalletStore.toggleBalanceModal()"
				>
					Deposit Store Credit
				</router-link>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { defineComponent, PropType, computed } from 'vue'
import Currency from '@/types/Currency'
import { useNewWalletStore } from '@/stores/NewWalletStore'
import BN from 'bn.js'

const newWalletStore = useNewWalletStore()

const bpxBalance = computed(() => {
	const val = newWalletStore.balances
		.reduce(function (bnBalance, bal) {
			if (bal.slug == 'bpx' || bal.slug == 'vbpx') {
				return bnBalance.add(new BN(bal.available))
			}

			return bnBalance
		}, new BN('0'))
		.toString()

	return new Currency(val)
})

const pendingAuctionCredit = computed(() => {
	return newWalletStore.balances.find((b) => b.slug == 'usd-auction-credit') ?? null
})

const storeCredit = computed(() => {
	return newWalletStore.balances.find((b) => b.slug == 'usd-store-credit') ?? null
})
</script>
