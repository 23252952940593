<template>
	<div class="bg-zinc-100 text-zinc-900">
		<Header />
		<form class="max-w-prose mx-auto px-4" @submit.prevent="handleSubmit">
			<div class="mt-2 w-full sm:w-3/4 mx-auto">
				<div class="text-xl font-poppins mt-6 mb-2">Enter the 4-digit code we sent you:</div>
				<input
					type="tel"
					class="text-4xl bg-zinc-50 text-black w-full border-2 border-zinc-400 rounded-md focus:border-teal-500 focus:ring-0 transition-colors text-center"
					placeholder="1234"
					required
					v-model="code"
				/>
			</div>

			<div class="mt-12 grid grid-cols-[auto,1fr] gap-x-2 gap-y-4">
				<div class="w-12 text-center">
					<input type="radio" name="sms_opt_in" v-model="smsOptIn" value="1" id="sms-opt-in-yes" required />
				</div>

				<label for="sms-opt-in-yes" class="font-medium">
					Yes! I want to be the first to see promotions and special offers for collectors. Please add me to
					the VIP LIST. I can reply STOP at any time to unsubscribe.
				</label>

				<div class="w-12 text-center">
					<input type="radio" name="sms_opt_in" v-model="smsOptIn" value="0" id="sms-opt-in-no" required />
				</div>

				<label for="sms-opt-in-no">
					No. I don't want first access to new stuff. Don't add me to the VIP LIST.
				</label>
			</div>

			<div class="mt-12 text-center">
				<button
					class="py-3 px-6 text-xl font-poppins hover:saturate-150 transition-all text-zinc-500"
					type="button"
					@click="handleBack"
				>
					<i class="far fa-chevron-left"></i> Back
				</button>

				<button
					class="py-3 px-24 bg-zinc-400 text-zinc-500 enabled:bg-teal-500 enabled:text-white transition-all hover:saturate-150 active:saturate-150 rounded-full text-xl font-poppins"
					type="submit"
					:disabled="submitting"
				>
					Finish <i class="far fa-chevron-right"></i>
				</button>
			</div>
		</form>

		<Footer />
	</div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { onMounted, unref, ref } from 'vue'

import { useLeadStore } from '@/stores/LeadStore'
import api from '@/util/leadsApi'
import Footer from '@/pages/Promo/Partials/Footer.vue'
import Header from '@/pages/Promo/Partials/Header.vue'

let code: string = null
let smsOptIn: string = null
const submitting = ref(false)

const router = useRouter()
const leadStore = useLeadStore()

onMounted(() => {
	if (unref(leadStore.id) === null) {
		router.push({ name: 'promo2.email' })
		return
	}
})

async function handleBack() {
	router.push({
		name: 'promo2.phone',
	})
}

async function handleSubmit() {
	try {
		submitting.value = true

		await api.confirmSMS(unref(leadStore.id), { code })

		await api.updateLead(unref(leadStore.id), { flag_sms_opt_in: smsOptIn })

		router.push({
			name: 'promo2.thank-you',
		})
	} catch (e) {
		alert("We couldn't handle your submission. Please try again.")
	} finally {
		submitting.value = false
	}
}
</script>
