<template>
	<div class="bg-white p-4 rounded-lg shadow-sm" :class="class">
		<div class="flex gap-2">
			<div class="w-12 flex-none"><img :src="props.avatar" /></div>
			<div>
				<div class="font-bold">
					{{ props.user }}
					<span v-if="props.verified" class="text-[rgb(29,155,240)]"><i class="fas fa-badge-check" /></span>
				</div>
				<div class="text-zinc-500">{{ props.username }}</div>
			</div>
		</div>
		<div class="mt-2">
			{{ props.text }}
		</div>
		<div class="mt-2" v-if="props.media">
			<div v-if="props.media.length == 1">
				<img :src="props.media[0].toString()" class="rounded-3xl max-h-[400px]" />
			</div>

			<div v-if="props.media.length == 4" class="grid grid-cols-2 grid-rows-2 gap-1 rounded-3xl overflow-hidden">
				<div
					class="aspect-video bg-cover bg-center"
					v-for="media in props.media"
					:style="'background-image:url(' + media + ')'"
				></div>
			</div>
		</div>
		<div class="mt-2">
			<a
				href="https://twitter.com/bjamps/status/1704352128309903497"
				target="_blank"
				rel="nofollow noreferrer"
				class="text-zinc-500 hover:underline"
				>{{ format(props.date, 'h:mm aa') }} &middot; {{ format(props.date, 'MMM d, y') }}</a
			>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { format } from 'date-fns'

const props = defineProps({
	link: {
		type: String,
	},
	user: String,
	username: String,
	avatar: String,
	verified: Boolean,
	text: String,
	media: {
		type: Array<String>,
	},
	date: Date,
	class: String,
})
</script>
