<template>
	<div class="relative h-48 bg-image-set mb-24">
		<div class="absolute inset-0 bg-gradient-to-t from-zinc-100 via-zinc-100/75 to-transparent"></div>

		<div class="absolute -bottom-10 w-full">
			<div class="w-32 mx-auto">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
					<circle cy="60" cx="60" r="54" fill="white" />

					<path
						class="logo"
						d="M57.79 0a57.79 57.79 0 1 0 57.79 57.79A57.79 57.79 0 0 0 57.79 0ZM29.68 79.41a2.35 2.35 0 0 1 .49 1.42v8.45a.79.79 0 0 1-1.31.59 43.19 43.19 0 0 1 15.71-73.22.8.8 0 0 1 1 .76v23.12a.77.77 0 0 0 1.2.66 19.9 19.9 0 0 1 26.9 4.61.79.79 0 0 1-.34 1.2l-5.67 2.41a1.19 1.19 0 0 1-1.31-.28 12.18 12.18 0 0 0-20.69 7.46v1.39a1.16 1.16 0 0 1-.72 1.08L39 61.58a.79.79 0 0 1-1.09-.73V30a.79.79 0 0 0-1.25-.64 35.4 35.4 0 0 0-7 50.09ZM57.79 101a43.31 43.31 0 0 1-12.18-1.74 42.2 42.2 0 0 1-6.84-2.67 1.58 1.58 0 0 1-.88-1.42v-24a1.18 1.18 0 0 1 .72-1.09L41.33 69l4.28-1.82 2.31-1a1.21 1.21 0 0 1 1.31.27A12.17 12.17 0 0 0 69.91 59a13 13 0 0 0 0-1.36 1.2 1.2 0 0 1 .72-1.09l5.69-2.41a.78.78 0 0 1 1.09.59 19.88 19.88 0 0 1-30.6 19.65.78.78 0 0 0-1.2.66v15.23a1.18 1.18 0 0 0 .79 1.13 35.5 35.5 0 1 0 7.79-68.91.78.78 0 0 1-.87-.77v-6.18a.79.79 0 0 1 .73-.79 43.84 43.84 0 0 1 9 .15 43.21 43.21 0 0 1-5.26 86.1Z"
					/>
				</svg>
			</div>
		</div>
	</div>
</template>

<style lang="postcss" scoped>
.bg-image-set {
	@apply bg-cover;
	@apply bg-[url(/image/promo-banner-top10-cards-1000x.webp)] md:bg-[url(/image/promo-banner-top10-cards.webp)];
}

svg {
	filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.15)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.3));
}
</style>
