<template>
	<modal>
		<template #default="handlers">
			<div class="bg-white rounded-xl overflow-hidden drop-shadow-md w-full">
				<div class="p-4 sm:p-6">
					<h1 class="text-xl border-b-2 border-gray-200 pb-1">Deposit Store Credit</h1>

					<p class="mt-4">Choose an amount:</p>
					<div class="mt-4 grid grid-cols-2 gap-4 text-center items-stretch">
						<button
							v-for="(opt, i) in options"
							v-key="i"
							class="group border-2 border-gray-200 shadow-md rounded-xl px-2 pt-2 relative transition-all"
							:class="buttonClasses(opt.amount)"
							@click="selectAmount(opt.amount)"
						>
							<div
								v-if="opt.mostPopular"
								class="text-sm absolute top-0 -left-3 block px-3 py-1 bg-red-500 text-white -rotate-12 rounded-xl shadow"
							>
								MOST POPULAR!
							</div>

							<div
								v-if="opt.bestValue"
								class="text-sm absolute top-0 -left-3 block px-3 py-1 bg-red-500 text-white -rotate-12 rounded-xl shadow"
							>
								BEST VALUE!
							</div>

							<div class="text-xl sm:text-3xl font-poppins font-medium mt-4 mb-2 sm:my-4">
								${{ opt.amount.toLocaleString() }}
							</div>

							<div
								v-if="opt.bonus > 0"
								class="text-emerald-500 border border-emerald-500 rounded py-1 px-2 inline-flex items-center justify-center gap-1 group-hover:text-white group-hover:border-white transition-all"
							>
								<div>+${{ opt.bonus.toLocaleString() }}</div>
								<div class="uppercase font-bold text-sm rounded">Bonus</div>
							</div>

							<div
								class="text-xs sm:text-sm group-hover:text-gray-50 my-4 transition-all"
								:class="subTextClasses(opt.amount)"
							>
								${{ (opt.amount + opt.bonus).toLocaleString() }} total credit
							</div>
						</button>
					</div>
				</div>

				<div class="mt-4 bg-gray-200 px-4 py-4 text-right space-x-4 border-t-2 border-gray-300">
					<button class="text-gray-500" @click="handlers.close">Cancel</button>
					<button class="btn-primary" :disabled="!amountValid" @click="handleNextButton">Next</button>
				</div>
			</div>
		</template>
	</modal>
</template>
<script lang="ts" setup>
import { computed, reactive, ref } from 'vue'

import { useMarketStore } from '@/stores/MarketStore'

const marketStore = useMarketStore()

const options: Array<{
	amount: number
	bonus: number | null
	image: string
	mostPopular: boolean
	bestValue: boolean
}> = [
	{
		amount: 25,
		bonus: 0,
		image: '/image/bpx_store-credit_25.webp',
		mostPopular: false,
		bestValue: false,
	},
	{
		amount: 50,
		bonus: 0,
		image: '/image/bpx_store-credit_50.webp',
		mostPopular: false,
		bestValue: false,
	},
	{
		amount: 100,
		bonus: 10,
		image: '/image/bpx_store-credit_100.webp',
		mostPopular: true,
		bestValue: false,
	},
	{
		amount: 250,
		bonus: 35,
		image: '/image/bpx_store-credit_250.webp',
		mostPopular: false,
		bestValue: false,
	},
	{
		amount: 500,
		bonus: 100,
		image: '/image/bpx_store-credit_500.webp',
		mostPopular: false,
		bestValue: false,
	},
	{
		amount: 1000,
		bonus: 250,
		image: '/image/bpx_store-credit_1000.webp',
		mostPopular: false,
		bestValue: true,
	},
]

const props = withDefaults(
	defineProps<{
		redirect: String | null
	}>(),
	{
		redirect: () => null,
	}
)

const state = reactive({
	optSelected: null,
	amount: 0,
	customAmount: null,
})

function selectAmount(opt: number) {
	state.optSelected = opt
}

function buttonClasses(opt: number) {
	if (state.optSelected == opt) {
		return 'bg-sky-700 text-white border-sky-700'
	}

	return 'bg-gray-100 hover:bg-sky-600 hover:text-white hover:border-sky-700/80'
}

function subTextClasses(opt) {
	if (state.optSelected == opt) {
		return 'text-gray-50'
	}

	return 'text-gray-600'
}

const amountValid = computed(() => {
	return state.optSelected !== null
})

function handleNextButton() {
	executePurchase(state.optSelected)
}

async function executePurchase(amount: number) {
	const response = await marketStore.initiateStoreCreditPurchase(amount, props.redirect)

	if (response.success) {
		window.top.location = response.redirect
		return
	}

	alert(response.message)
}
</script>
