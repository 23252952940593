/* eslint-disable no-await-in-loop */
import { Alchemy, Network } from 'alchemy-sdk'

export class AlchemyApi {
	constructor() {
		this.sdks = {}
		// mainnet
		this.sdks[1] = new Alchemy({
			apiKey: 'VZbemlrDSsUEVE7Rj2G595evbAiR5MTF',
			network: Network.ETH_MAINNET,
		})
		// goerli
		this.sdks[5] = new Alchemy({
			apiKey: 'BVW3Mjpciov9Bfx1rtRxz5w0jpXP0Z2p',
			network: Network.ETH_GOERLI,
		})
		// polygon
		this.sdks[137] = new Alchemy({
			apiKey: 'V56Liw9iau9XOBr5cDyybK_wtBJauaCM',
			network: Network.MATIC_MAINNET,
		})
		// mumbai
		this.sdks[80001] = new Alchemy({
			apiKey: 'z_EO3pyUfzd1tHdXwyTL6yWdhZayI1GR',
			network: Network.MATIC_MUMBAI,
		})
	}

	selectSdk(chainId) {
		const sdk = this.sdks[chainId]
		if (!sdk) {
			const err = Error(`Alchemy not configured for chain id '${chainId}`)
			return Promise.reject(err)
		}

		return sdk
	}

	async getOwnedTokens(chainId, wallet, contractAddresses) {
		const sdk = this.selectSdk(chainId)
		let data = []
		let pageKey
		do {
			const response = await sdk.nft.getNftsForOwner(wallet, {
				contractAddresses: [contractAddresses],
				omitMetadata: false,
				pageSize: 100,
				pageKey,
			})
			data = data.concat(response.ownedNfts)
			pageKey = response.pageKey
		} while (pageKey !== undefined)

		return data
	}

	async resolveWallet(chainId, ens) {
		const sdk = this.selectSdk(chainId)

		return sdk.core.resolveName(ens)
	}

	async getTokenMeta(chainId, contract, tokenId, itemType) {
		const sdk = await this.selectSdk(chainId).catch((err) => Promise.reject(err))

		let nft
		try {
			nft = await sdk.nft.getNftMetadata(contract, tokenId, undefined, 3000)
		} catch {}
		return nft
	}
}
