import { DateTime } from 'ts-luxon'
import type { Tag } from './Taxonomy'

export interface Auction {
	id: number
	blockchain_auction_id: number | null
	seller_token_id: number | null
	title: string
	description: string
	md_description: string
	starting_bid: string
	bid_increment: string
	buyer_premium_bps: number

	current_status: number
	auction_group: AuctionGroup
	start_at: Date
	end_at: Date
	extended_bidding_seconds: number

	bid_count: number
	high_bid: string | null
	high_bidder: string | null
	last_bid_at: Date | null

	images: AuctionImage[]
	bids: Bid[]
	tags: Tag[]

	claimed_at: Date | null
	claimed_by: string | null
	claim_recipient: string | null
	claim_tx_hash: string | null

	proceeds_claimed_at: Date | null
	proceeds_claimed_by: string | null
	proceeds_claim_recipient: string | null
	proceeds_claim_tx_hash: string | null
}

export interface AuctionImage {
	uri: string
	display_order: number
}

export interface Bid {
	auction_id: string
	blockchain_auction_id: string
	bid: string
	bidder: string
	tx_hash: string
	bid_at: Date

	auction: Auction | null
}

export interface AuctionGroup {
	id: number
	name: string
	slug: string
	image: string
	start_at: Date
	end_at: Date
}

export const StatusClosed = 0
export const StatusOpen = 1
export const StatusExtended = 2
export const StatusEnded = 3
export const StatusDoesNotExist = 4
export const StatusCancelled = 5
