import { DateTime } from 'ts-luxon'
import { Storefront } from './Storefront'

export interface Attribute {
	trait_type: string
	value: string
}

export interface Metadata {
	id: string | null
	image: string | null
	attributes: Attribute[]
}

export interface Asset {
	id: number
	asset_identifier: string | null
	name: string
	image: string
	price?: number
	storefront_id: string
	metadata: Metadata

	owners?: OwnersList[]
	listings?: Listing[]

	storefront?: Storefront
}

export interface AssetList {
	assets: Asset[]
}

export interface Owners {
	id: number
	quantity: number
}

export interface OwnersList {
	owners: Owners[]
}

export type ListingType = 'fixed' | 'auction'
export const TYPE_FIXED: ListingType = 'fixed'
export const TYPE_AUCTION: ListingType = 'auction'

export interface NewListing {
	id?: string
	token_id: number
	storefront_id: string
	listing_type: ListingType
	price: number | string
	quantity: number
	expires_at: DateTime | string | null
}

export interface Listing {
	id: string | null
	token_id: number
	storefront_id: string
	seller_user_id: string | null
	listing_type: ListingType
	price: number | string
	quantity: number
	listing_status: string | null
	expires_at: DateTime | string

	high_bid_amount?: number
	bid_increment?: number
	viewer_is_high_bidder?: boolean
	extended_bidding_seconds?: number
	extended_bidding_begins_at?: DateTime | string
	extended_bidding_ends_at?: DateTime | string

	owner: {
		id: string
		username: string
	}
}

export function makeAsset(data: object): Asset {
	const a = {
		id: data.id,
		storefront_id: data.storefront_id,
		asset_identifier: data.asset_identifier,
		name: data.asset.name,
		image: data.asset.image,
		metadata: data.asset.metadata ?? null,
		owners: [] as OwnerList[],
		listings: [] as Listing[],
		auctions: [] as Listing[],
		storefront: data.storefront ?? null,
	} as Asset

	if (data.asset.listings) {
		a.listings = data.asset.listings.map((l) => makeListing(l)) as Listing[]
	}

	if (data.asset.owners) {
		a.owners = data.asset.owners
	}

	return a
}

export function makeListing(data: object): Listing {
	const l = {
		id: data.id,
		token_id: data.token_id,
		storefront_id: data.storefront_id,
		seller_user_id: data.seller_blokpax_user_id,
		listing_type: data.listing_type,
		price: data.price,
		quantity: data.quantity,
		listing_status: data.listing_status,
		expires_at: DateTime.fromISO(data.expires_at).setZone(DateTime.local().zoneName),

		high_bid_amount: data.high_bid_amount,
		bid_increment: data.bid_increment,
		viewer_has_bid: data.viewer_has_bid,
		viewer_is_high_bidder: data.viewer_is_high_bidder,
		viewer_max_bid: data.viewer_max_bid,
		extended_bidding_seconds: data.extended_bidding_seconds,
		extended_bidding_ends_at:
			DateTime.fromISO(data.extended_bidding_ends_at).setZone(DateTime.local().zoneName) ?? null,

		owner: data.owner,
		bids: data.bids ?? [],
	} as Listing

	return l
}

export interface Contract {
	address: string
	contractDeployer: string
	deployedBlockNumber: number
	name: string
	openSea: any
	symbol: string
	tokenType: string
	totalSupply: any
}

export interface OwnedToken {
	aquiredAt: any
	balance: number
	contract: Contract
	description: string
	media: any[]
	metadataError: any
	rawMetadata: {
		attributes: { value: string; trait_type: string }[]
		id: string
		image: string
		name: string
	}
	spanInfo: any
	timeLastUpdated: string
	title: string
	tokenId: string
	tokenType: string
	tokenUri: {
		gateway: string
		raw: string
	}
}
