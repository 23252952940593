<template>
	<div class="grid grid-cols-3 gap-2 items-center mt-4 w-3/4 mx-auto">
		<button
			class="text-center text-xl bg-zinc-300 text-zinc-400 enabled:bg-teal-500 enabled:text-teal-900 enabled:hover:saturate-150 transition-all aspect-[4/3] rounded-l-full rounded-r-md enabled:shadow-lg shadow-zinc-950/30 focus-visible:outline-0 focus-visible:ring-2 ring-teal-700 select-none"
			@click.prevent="decrement"
			type="button"
			:disabled="value - 1 < props.min"
		>
			<i class="fas fa-minus"></i>
		</button>
		<input
			type="tel"
			@change="handleChange"
			class="text-center text-2xl font-extrabold bg-zinc-100 text-zinc-900 h-full grid place-content-center rounded-md"
			autocomplete="off"
			:value="value"
		/>
		<button
			class="text-center text-xl bg-zinc-300 text-zinc-400 enabled:bg-teal-500 enabled:text-teal-900 enabled:hover:saturate-150 transition-all aspect-[4/3] rounded-r-full rounded-l-md enabled:shadow-lg shadow-zinc-950/30 focus-visible:outline-0 focus-visible:ring-2 ring-teal-700 select-none"
			@click.prevent="increment"
			type="button"
			:disabled="value + 1 > props.max"
		>
			<i class="fas fa-plus"></i>
		</button>
	</div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

const props = defineProps({
	default: {
		type: Number,
		default: 0,
	},
	step: {
		type: Number,
		default: 1,
	},
	min: {
		type: Number,
		default: 0,
	},
	max: {
		type: Number,
	},
})

const emit = defineEmits(['update:modelValue'])

const value = ref(props.default)

function handleChange(evt) {
	let v = evt.target.value
	v = Math.max(props.min, v)
	v = Math.min(props.max, v)

	value.value = v

	emit('update:modelValue', value.value)
}

function decrement() {
	value.value = value.value - props.step < props.min ? props.min : value.value - props.step
	emit('update:modelValue', value.value)
}

function increment() {
	value.value = value.value + props.step > props.max && props.max !== undefined ? props.max : value.value + props.step
	emit('update:modelValue', value.value)
}
</script>
