<template>
	<div class="text-center">
		<p class="text-xl text-center text-slate-500 pt-8">Auction is loading...</p>
		<loader class="mx-auto" />
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
	setup(props) {
		return {}
	},
})
</script>
