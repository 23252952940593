import { defineStore } from 'pinia'
import { useAuctionStore } from './AuctionStore'
import {
	Auction,
	StatusCancelled,
	StatusClosed,
	StatusDoesNotExist,
	StatusEnded,
	StatusExtended,
	StatusOpen,
} from '@/types/Auction'
import { DateTime } from 'ts-luxon'

const pendingStatusUpdates: Auction[] = []
let statusUpdateInterval

export const useAuctionStatusStore = defineStore('auctionStatus', {
	state: () => ({
		auctions: new Map<number, { interval: number; status: number }>(),
	}),

	actions: {
		initStatusUpdateInterval() {
			if (statusUpdateInterval) {
				return
			}

			statusUpdateInterval = setInterval(this.executeStatusUpdates, 500)
		},

		pauseStatusUpdates() {
			clearInterval(statusUpdateInterval)
			statusUpdateInterval = 0
		},

		executeStatusUpdates() {
			this.pauseStatusUpdates()
			const updates = pendingStatusUpdates.splice(0)
			// if (updates.length) {
			// 	console.log("Executing ", updates.length, ' status updates')
			// }
			for (const auction of updates) {
				this.updateAuctionStatus(auction)
			}
			this.initStatusUpdateInterval()
		},

		isOpen(auction: Auction): boolean {
			return [StatusOpen, StatusExtended].indexOf(this.status(auction.id)) >= 0
		},

		isExtended(auction: Auction): boolean {
			return StatusExtended == this.status(auction.id)
		},

		isEnded(auction: Auction): boolean {
			return StatusEnded == this.status(auction.id)
		},

		updateAuctionStatus(auction: Auction): void {
			// console.log('updating auction status', auction.id)
			const currentStatus = this.getAuctionStatus(auction)

			let intervalDelta = 0
			const now = DateTime.now()

			if (now < auction.auction_group.end_at) {
				if ([StatusOpen, StatusExtended].indexOf(currentStatus) >= 0) {
					// check to see if the auction is currently open
					// and calculate the time until it ends
					let diff = auction.auction_group.end_at.diff(now)
					intervalDelta = diff.milliseconds
				} else if (currentStatus == StatusClosed) {
					// if auction is not open
					// calculate the time until it starts
					let diff = now.diff(auction.auction_group.start_at)
					intervalDelta = diff.milliseconds
				}
			} else {
				let diff = auction.end_at.diff(now)
				intervalDelta = diff.milliseconds
			}

			// console.log('Status:', currentStatus, auction.title, '-', now, auction.end_at,(now > auction.end_at? 'ends later' : 'ended'), intervalDelta, 'ms', intervalDelta / (1000 * 60), 'mins')
			// console.log('setting timeout to update auction status', auction.id, 'interval: ', intervalDelta, 'ms')

			this.auctions.set(auction.id, {
				interval:
					intervalDelta > 0
						? setTimeout(() => {
								// console.log('queueing status update', auction.title)
								this.queueStatusUpdate(auction)
						  }, intervalDelta)
						: 0,
				status: currentStatus,
			})
			// console.log('Scheduled Update', intervalDelta, 'ms', auction.title)

			this.initStatusUpdateInterval()
		},

		queueStatusUpdate(auction: Auction) {
			pendingStatusUpdates.push(auction)
		},

		getAuctionStatus(auction: Auction): number {
			// console.log('updating auction status')
			if ([StatusEnded, StatusDoesNotExist, StatusCancelled].indexOf(auction.current_status) >= 0) {
				return auction.current_status
			}

			const now = DateTime.now()

			if (now < auction.auction_group.start_at) {
				// console.log('CLOSED: ', auction.id, auction.title)
				return StatusClosed
			}

			if (now < auction.auction_group.end_at) {
				// console.log('OPEN: ', auction.id, auction.title)
				return StatusOpen
			}

			if (now > auction.auction_group.end_at && now < auction.end_at) {
				// console.log('EXTENDED: ', auction.id, auction.title)
				return StatusExtended
			}

			// if (now >= auction.end_at) {
			// this should be the only remaining case
			// console.log('ENDED: ', auction.id, auction.title)
			return StatusEnded
			// }
		},
	},

	getters: {
		status(state) {
			return (auctionId: number) => {
				if (!state.auctions.get(auctionId)) {
					const auctionStore = useAuctionStore()
					if (!auctionStore.auctions) return StatusDoesNotExist
					const auction = auctionStore.auctions.find((a) => a.id == auctionId)

					if (auction) this.updateAuctionStatus(auction)
				}

				return state.auctions.get(auctionId)?.status ?? StatusDoesNotExist
			}
		},
	},
})
