export class Flag {
	flag: string
	requireAuth: boolean

	constructor(flag: string, requireAuth: boolean = true) {
		this.flag = flag
		this.requireAuth = requireAuth
	}

	public() : boolean {
		return ! this.requireAuth
	}
}

export const Flags = {
	'AUCTIONS_CREATE': new Flag('AUCTIONS_CREATE'),

	'FEATURE_AUCTIONS': 'FEATURE_AUCTIONS',
}
