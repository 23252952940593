import { defineStore } from 'pinia'
import { Auction, StatusEnded, StatusExtended, StatusOpen } from '@/types/Auction'
import api from '@/util/api'
import sleep from '@/util/sleep'
import BN from 'bn.js'
import UnitConverter from '../types/Currency'
import { useTaxonomyStore } from './TaxonomyStore'

import { DateTime } from 'ts-luxon'
import Bugsnag from '@bugsnag/js'
import { useAuctionFilterStore } from './AuctionFilterStore'
import { useWalletStore } from './WalletStore'
import { useAuctionStatusStore } from './AuctionStatusStore'

interface AuctionState {
	echo: Echo
	auctions: Auction[] | null
	currentAuctionID: number | null
	page: number
	perPage: number
	sort: string
}

export const useAuctionStore = defineStore('auction', {
	state: () =>
		({
			currentAuctionID: null,
			auctions: null,

			// pagination for auction index
			page: 0,
			perPage: 25,
			sort: 'end_time',
		} as AuctionState),

	actions: {
		addBid(bid) {
			const newBid = {
				...bid,
				bid_at: DateTime.fromISO(bid.bid_at),
			}

			if (this.auctions) {
				let a = this.auctions.filter((a) => a.id == bid.auction_id)
				if (a.length) {
					a[0].bids.unshift(newBid)
				}
			}
		},

		async fetchAuctions(force: boolean | undefined): Promise<Auction[]> {
			if (null == this.auctions || this.auctions.length == 0 || force) {
				// console.log('loading from api');
				Bugsnag.leaveBreadcrumb('Loading auctions')
				const wait = sleep(1.25)
				const a = await api.fetchAuctions()
				await wait
				this.auctions = a
			}

			return this.auctions
		},

		async fetchAuction(id: number): Promise<Auction | null> {
			this.currentAuctionID = id
			const wait = sleep(0.25)
			const auctions = await this.fetchAuctions()
			const auction = auctions.filter((a) => a.id == id)[0] || null
			await wait
			return auction
		},

		async notifyBid(txHash: string): Promise {
			return api.notifyBid(txHash)
		},

		recordHighBid(auction: any): void {
			let updateAuctions = []
			if (this.auctions) {
				const a = this.auctions.filter((a) => a.id == auction.id)

				if (a.length) {
					updateAuctions.push(a[0])
				}
			}

			updateAuctions.map((a) => {
				// console.log('updating auction high bidder', a);
				a.high_bidder = auction.high_bidder
				a.high_bid = auction.high_bid
				a.last_bid_at = DateTime.fromISO(auction.last_bid_at)
				a.end_at = DateTime.fromISO(auction.end_at)
			})
		},

		groupAuctions(id: number): Auction[] {
			if (!this.auctions) {
				return [] as Auction[]
			}

			return this.auctions.filter((a) => a.auction_group.id == id)
		},

		isAuctionActive(auction: Auction): boolean {
			return (
				(auction.current_status == StatusOpen || auction.current_status == StatusExtended) &&
				DateTime.now() <
					Math.max(
						(auction.last_bid_at ?? auction.auction_group.end_at).plus(auction.extended_bidding_seconds),
						auction.auction_group.end_at.plus(auction.extended_bidding_seconds)
					)
			)
		},

		isAuctionEnded(auction: Auction): boolean {
			return (
				auction.current_status == StatusEnded ||
				DateTime.now() >
					Math.max(
						(auction.last_bid_at ?? auction.auction_group.end_at).plus(auction.extended_bidding_seconds),
						auction.auction_group.end_at.plus(auction.extended_bidding_seconds)
					)
			)
		},
	},

	getters: {
		auctionGroups(): AuctionGroup[] {
			if (!this.auctions) {
				return [] as AuctionGroup[]
			}

			return Object.values(
				this.auctions.reduce((all, a) => {
					if (undefined == all[a.auction_group.id]) {
						all[a.auction_group.id] = a.auction_group
					}

					return all
				}, {})
			)
		},

		auction(): Auction | null {
			if (!this.currentAuctionID || !this.auctions || this.auctions.length == 0) {
				return null
			}

			return this.auctions?.filter((a) => a.id == this.currentAuctionID)[0] || null
		},

		tagMap(): Map<number, number[]> {
			let tags: Map<number, number[]> = new Map()
			this.auctions?.map((auction) => {
				auction.tags.map((t) => {
					let tmpAuctions = [] as number[]
					if (tags.has(t.id)) {
						tmpAuctions = tags.get(t.id)
					}

					tmpAuctions.push(auction.id)
					tags.set(t.id, tmpAuctions)
				})
			})

			return tags
		},

		tagMapByGroup:
			(state: AuctionState) =>
			(id: number): Map<number, number[]> => {
				const tags: Map<number, number[]> = new Map()

				state.auctions
					?.filter((auction: Auction) => auction.auction_group.id == id)
					.map((auction: Auction) => {
						auction.tags.map((t) => {
							if (!tags.has(t.id)) {
								tags.set(t.id, [])
							}

							tags.get(t.id)?.push(auction.id)
						})
					})

				return tags
			},

		filteredAuctions(): Auction[] {
			const taxonomyStore = useTaxonomyStore()
			const auctionFilterStore = useAuctionFilterStore()
			const walletStore = useWalletStore()
			let auctionIds = this.auctions?.map((auction) => auction.id)

			if (Object.keys(taxonomyStore.activeFilters).length > 0) {
				taxonomyStore.taxonomy?.map((category) => {
					let categoryActive = false
					let categoryAuctions = [] as number[]
					category.tags.map((tag) => {
						if (taxonomyStore.activeFilters[tag.id]) {
							categoryActive = true
							categoryAuctions = [
								...new Set([
									...categoryAuctions,
									...(this.tagMap.has(tag.id) ? this.tagMap.get(tag.id) : []),
								]),
							]
						}
					})

					if (categoryActive) {
						auctionIds = auctionIds?.filter((id) => categoryAuctions.indexOf(id) >= 0)
					}
				})
			}

			if (!this.auctions || 0 == this.auctions.length) {
				return [] as Auction[]
			}

			let auctions = this.auctions?.filter((a) => auctionIds?.indexOf(a.id) >= 0)

			const auctionFilters = Object.keys(auctionFilterStore.activeFilters)
			const activeFilters = auctionFilterStore.allFilters.filter((f) => auctionFilters.indexOf(f.id) >= 0)

			if (0 == activeFilters.length) {
				return auctions
			}

			return auctions.filter((a) => {
				let passes = false
				activeFilters.map((f) => {
					passes =
						passes ||
						f.passes({
							auction: a,
							user: walletStore.wallet,
						})
				})

				return passes
			})
		},

		activeAuctions(): Auction[] {
			const auctionStatusStore = useAuctionStatusStore()
			return this.auctions?.filter(auctionStatusStore.isOpen) ?? ([] as Auction[])
			// return this.auctions?.filter(this.isAuctionActive) ?? [] as Auction[]
		},

		endedAuctions(): Auction[] {
			const auctionStatusStore = useAuctionStatusStore()
			return this.auctions?.filter(auctionStatusStore.isEnded) ?? ([] as Auction[])
		},
	},
})
