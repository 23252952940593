<template>
	<div>
		<div>
			<template v-if="bids.length">
				<div
					v-for="bid in bids"
					:key="`bid-${bid.bid_at}-${bid.bidder}`"
					class="border-b-2 border-gray-100 pb-4 mb-4"
				>
					<p class="font-semibold">
						{{ $format(new Currency(bid.bid).toDecimal()) }} {{ appStore.tokenSymbol }}
						<span :title="bid.bidder" class="text-slate-500 text-sm font-normal">
							by
							{{
								resolvedNames[bid.bidder.toLowerCase()] ||
								lookupName(bid.bidder) ||
								$shorten(bid.bidder)
							}}
						</span>
					</p>
					<p class="text-slate-500 text-sm">
						at
						<a
							:href="`http://etherscan.io/tx/${bid.tx_hash}`"
							title="View on etherscan"
							target="_blank"
							class="text-slate-600 underline"
						>
							{{ bid.bid_at.toFormat('f ZZZZ') }}
						</a>
					</p>
				</div>
			</template>
			<p v-else class="py-4 text-center text-slate-400">No bids yet</p>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed, watch, ref } from 'vue'
import { useAppStore } from '../stores/AppStore'
import Currency from '../types/Currency'
import useEns from '@/util/ens'

export default defineComponent({
	props: {
		bids: {
			required: true,
		},
	},
	setup(props) {
		const appStore = useAppStore()
		const bids = ref(sortBids())
		const { reverse } = useEns(import.meta.env.VITE_ENS_PROVIDER)
		const resolvedNames = ref({})

		watch(
			() => props.bids,
			() => {
				// console.log('resorting bids')
				bids.value = sortBids()
			}
		)

		function sortBids() {
			return props.bids.sort((left, right) => left - right)
		}

		function lookupName(address: string) {
			reverse(address).then((result) => {
				if (result) {
					resolvedNames.value[address.toLowerCase()] = result
				}
			})

			return false
		}

		return {
			props,
			appStore,
			Currency,
			bids,
			resolvedNames,
			lookupName,
		}
	},
})
</script>
