<template>
	<div class="flex">
		<login-button v-if="!authStore.authenticated" />
		<nav-account-menu v-else />
	</div>
</template>
<script lang="ts">
import { useAuthStore } from '@/stores/AuthStore'
import { defineComponent, onMounted, PropType } from 'vue'
import LoginButton from './LoginButton.vue'
import NavAccountMenu from './NavAccountMenu.vue'

export default defineComponent({
	components: { LoginButton, NavAccountMenu },
	setup(props) {
		const authStore = useAuthStore()

		onMounted(async () => {
			await authStore.login()
		})

		return {
			authStore,
		}
	},
})
</script>
