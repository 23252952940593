<template>
	<div class="">
		<div class="">
			<h2 class="font-semibold text-xl flex-grow text-center sm:text-left">Filters</h2>
			<div class="mt-2">
				<filter-option
					label="Listed"
					@click.prevent="storeStore.toggleFilter('Listed', 'Yes')"
					:selected="(storeStore.state.activeFilters?.Listed ?? []).indexOf('Yes') >= 0"
				/>
			</div>
		</div>
		<div class="mt-6 p-4 md:p-0 flex-grow flex-shrink overflow-y-auto">
			<div class="flex justify-between">
				<h2 class="font-semibold text-xl flex-grow text-center sm:text-left">Collections</h2>
				<button class="sm:hidden" @click.prevent="$emit('close')">
					<i class="fa-regular fa-sharp fa-times text-xl" />
				</button>
			</div>

			<div class="mt-4 flex-grow">
				<tile-loader v-if="props.loading" :count="6" class="space-y-4">
					<div class="flex space-x-2 items-center">
						<div class="w-1/5 flex-grow-0 flex-shrink-0">
							<div class="rounded-lg overflow-hidden border border-gray-300 aspect-square">
								<loading-placeholder class="w-full h-full" />
							</div>
						</div>
						<div class="overflow-hidden w-4/5">
							<loading-placeholder class="w-full rounded-lg">
								<p>&nbsp;</p>
							</loading-placeholder>
						</div>
					</div>
				</tile-loader>
				<div v-else class="space-y-2">
					<storefront-filter-item
						class="cursor-pointer hover:bg-gray-100 active:bg-gray-200 rounded-lg mouse-down"
						v-for="storefront in props.assetStorefronts"
						:key="`storefront-${storefront.id}`"
						:storefront="storefront"
						:class="[storefrontSelected(storefront) ? 'bg-gray-100' : '']"
						@click.prevent="$emit('toggle-storefront', storefront)"
					/>

					<storefront-filters
						class="pt-4"
						v-if="props.filteredStorefronts.length == 1 && storeStore.state.storefront"
						:closable="false"
					/>
				</div>
			</div>
		</div>

		<div
			class="sm:hidden flex-shrink-0 flex-grow-0 flex justify-between space-x-2 py-4 px-4 border-t border-gray-200"
		>
			<button
				class="shadow hover:shadow-none border border-gray-200 rounded-xl text-center w-full text-sky-600"
				@click.prevent="$emit('clear')"
			>
				Clear all
			</button>
			<button class="shadow hover:shadow-none btn-primary-lg w-full" @click.prevent="$emit('done')">Done</button>
		</div>
	</div>
</template>
<script lang="ts">
import { useStorefrontStore } from '@/stores/StorefrontStore'
import { Storefront } from '@/types/Storefront'
import { defineComponent, PropType } from 'vue'
import TileLoader from '@/components/TileLoader.vue'
import LoadingPlaceholder from '@/components/LoadingPlaceholder.vue'
import StorefrontFilterItem from '@/components/StorefrontFilterItem.vue'
import StorefrontFilters from '@/components/StorefrontFilters.vue'
import FilterOption from '@/components/FilterOption.vue'
export default defineComponent({
	components: { TileLoader, LoadingPlaceholder, StorefrontFilterItem, StorefrontFilters, FilterOption },
	emits: ['toggle-storefront', 'done', 'clear'],
	props: {
		loading: {
			required: false,
			type: Boolean,
			default: () => false,
		},
		assetStorefronts: {
			required: true,
			type: Object as PropType<Storefront[]>,
		},
		filteredStorefronts: {
			required: true,
			type: Object as PropType<Storefront[]>,
		},
	},
	setup(props, { emit: $emit }) {
		const storeStore = useStorefrontStore()

		function storefrontSelected(storefront: Storefront): boolean {
			return !!props.filteredStorefronts.find((s) => s.id == storefront.id)
		}

		return {
			props,
			storeStore,
			storefrontSelected,
		}
	},
})
</script>
