import { createRouter, createWebHistory } from 'vue-router'

// import IndexPage from '@/pages/Index.vue';
import Storefront from '@/pages/Storefront.vue'
import Activity from '@/pages/Activity.vue'
import AuctionList from '@/pages/AuctionList.vue'
import AuctionIndex from '@/pages/AuctionIndex.vue'
import AuctionDetail from '@/pages/AuctionDetail.vue'
import AccountPage from '@/pages/AccountPage.vue'
import TosExit from '@/pages/TosExit.vue'
import OAuthCallback from '@/pages/OAuthCallback.vue'
import AuthHandoff from '@/pages/AuthHandoff.vue'
import Index from './pages/Index.vue'
import AssetDetail from '@/pages/AssetDetail.vue'
import ListAsset from '@/pages/ListAsset.vue'
import TermsOfService from '@/pages/TermsOfService.vue'
import StoreCredit from './pages/StoreCredit.vue'
import StorefrontLayout from './pages/Storefront/StorefrontLayout.vue'
import StorefrontCollection from './pages/Storefront/StorefrontCollection.vue'
import StorefrontHeroLeaderBoard from './pages/Storefront/StorefrontHeroLeaderboard.vue'
import StorefrontActivity from './components/StorefrontActivity.vue'

import PromoEmail from '@/pages/Promo/Email.vue'
import PromoPhone from '@/pages/Promo/Phone.vue'
import PromoPhoneVerify from '@/pages/Promo/PhoneVerify.vue'
import PromoThankYou from '@/pages/Promo/ThankYou.vue'

import PromoEmail2 from '@/pages/Promo/promo2/Email2.vue'
import PromoPhone2 from '@/pages/Promo/promo2/Phone2.vue'
import PromoPhoneVerify2 from '@/pages/Promo/promo2/PhoneVerify2.vue'
import PromoThankYou2 from '@/pages/Promo/promo2/ThankYou2.vue'

import PromoEmail3 from '@/pages/Promo/promo3/Email3.vue'
import PromoPhone3 from '@/pages/Promo/promo3/Phone3.vue'
import PromoPhoneVerify3 from '@/pages/Promo/promo3/PhoneVerify3.vue'
import PromoThankYou3 from '@/pages/Promo/promo3/ThankYou3.vue'

import BlankLayout from '@/layouts/Blank.vue'
import AccountLayout from './pages/Account/AccountLayout.vue'
import AccountCollection from './pages/Account/Collection.vue'
import AccountActivity from './pages/Account/AccountActivity.vue'
import AccountWallet from './pages/Account/AccountWallet.vue'
import AccountListings from './pages/Account/AccountListings.vue'

const routes = [
	{
		path: '/',
		component: Index,
		name: 'home',
		meta: {
			title: 'Blokpax Market',
		},
	},
	{
		path: '/get-credit',
		component: StoreCredit,
		name: 'buy-store-credit',
	},
	{
		path: '/store/:slug',
		component: StorefrontLayout,
		children: [
			{
				path: '',
				component: StorefrontCollection,
				name: 'storefront',
			},
			{
				path: 'activity',
				component: StorefrontActivity,
				name: 'storefront-activity',
			},
			{
				path: 'leaderboard',
				component: StorefrontHeroLeaderBoard,
				name: 'storefront:leaderboard',
			},
		],
		meta: {
			title: 'Blokpax Market',
		},
	},
	{
		path: '/store/:slug/:assetId(\\d+)',
		component: AssetDetail,
		name: 'asset',
		meta: {
			title: '',
		},
	},
	{
		path: '/store/:slug/:assetId/list/',
		component: ListAsset,
		name: 'list-asset',
		meta: {
			title: '',
		},
	},
	{
		path: '/store/:slug/:assetId/listing/:listingId/edit',
		component: ListAsset,
		name: 'edit-asset-listing',
		meta: {
			title: '',
		},
	},
	{
		path: '/store/:slug/:assetId/listing/:listingId',
		component: AssetDetail,
		name: 'purchase-listing',
	},
	{
		path: '/auctions',
		component: AuctionList,
		name: 'auction-index',
		meta: {
			title: 'Active Auctions',
		},
	},
	{
		path: '/:group',
		component: AuctionIndex,
		name: 'auction-listings',
		meta: {
			title: 'Active Auctions',
		},
	},
	{
		path: '/:group/:auction(\\d+)',
		component: AuctionDetail,
		name: 'auction',
	},
	{
		path: '/account',
		component: AccountLayout,
		children: [
			{
				path: '',
				component: AccountCollection,
				name: 'account',
			},
			{
				path: 'activity',
				component: AccountActivity,
				name: 'account:activity',
			},
			{
				path: 'wallet',
				component: AccountWallet,
				name: 'account:wallet',
			},
			{
				path: 'listings',
				component: AccountListings,
				name: 'account:listings',
			},
		],
	},
	{
		path: '/tos-exit',
		component: TosExit,
	},
	{
		path: '/auth/callback',
		component: OAuthCallback,
	},
	{
		path: '/auth/handoff',
		component: AuthHandoff,
	},
	{
		path: '/terms-of-service',
		component: TermsOfService,
	},
	{
		path: '/promo',
		name: 'promo',
		children: [
			{
				name: 'promo.email',
				path: '',
				component: PromoEmail,
				meta: { layout: BlankLayout },
			},
			{
				name: 'promo.phone',
				path: 'phone',
				component: PromoPhone,
				meta: { layout: BlankLayout },
			},
			{
				name: 'promo.sms-verify',
				path: 'phone/verify',
				component: PromoPhoneVerify,
				meta: { layout: BlankLayout },
			},
			{
				name: 'promo.thank-you',
				path: 'thank-you',
				component: PromoThankYou,
				meta: { layout: BlankLayout },
			},
		],
	},
	{
		path: '/promo2',
		name: 'promo2',
		children: [
			{
				name: 'promo2.email',
				path: '',
				component: PromoEmail2,
				meta: { layout: BlankLayout },
			},
			{
				name: 'promo2.phone',
				path: 'phone',
				component: PromoPhone2,
				meta: { layout: BlankLayout },
			},
			{
				name: 'promo2.sms-verify',
				path: 'phone/verify',
				component: PromoPhoneVerify2,
				meta: { layout: BlankLayout },
			},
			{
				name: 'promo2.thank-you',
				path: 'thank-you',
				component: PromoThankYou2,
				meta: { layout: BlankLayout },
			},
		],
	},
	{
		path: '/promo3',
		name: 'promo3',
		children: [
			{
				name: 'promo3.email',
				path: '',
				component: PromoEmail3,
				meta: { layout: BlankLayout },
			},
			{
				name: 'promo3.phone',
				path: 'phone',
				component: PromoPhone3,
				meta: { layout: BlankLayout },
			},
			{
				name: 'promo3.sms-verify',
				path: 'phone/verify',
				component: PromoPhoneVerify3,
				meta: { layout: BlankLayout },
			},
			{
				name: 'promo3.thank-you',
				path: 'thank-you',
				component: PromoThankYou3,
				meta: { layout: BlankLayout },
			},
		],
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				if (to.hash) {
					resolve({ el: to.hash })
				}

				resolve(savedPosition || { top: 0 })
			}, 500)
		})
		// if (to.hash) {
		// 	return { el: to.hash }
		// }

		// return savedPosition || { top: 0 }

		// return { top: 0 }
	},
})

export default router
