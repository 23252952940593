import { defineStore } from 'pinia'
import api from '@/util/api'
import { Flags, Flag } from '@/types/Flags';
import { useAuthStore } from './AuthStore';

export const useFlagStore = defineStore('flagStore', () => {
	const authStore = useAuthStore();

	function setup() {

		return {
			// actions
			checkFlag
		}
	}

	function checkFlag(flag: Flag): boolean {
		if (authStore.user?.flags?.market) {
			if (authStore.user.flags.market.indexOf(flag.flag) !== -1) {
				return true;
			}
		}

		return flag.public() ?? false
	}

	return setup()
});
