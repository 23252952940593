<template>
	<div class="text-sm text-center mt-48 pb-4">
		&copy; 2023 Blokpax &middot;
		<a
			href="https://blokpax.com/terms-of-service"
			target="_blank"
			class="underline decoration-teal-500 hover:no-underline underline-offset-2"
			>Terms of Service</a
		>
		&middot;
		<a
			href="https://blokpax.com/privacy"
			target="_blank"
			class="underline decoration-teal-500 hover:no-underline underline-offset-2"
			>Privacy Policy</a
		>
	</div>
</template>
