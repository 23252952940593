<template>
	<router-link custom v-slot="{ navigate }" :to="props.url">
		<button @click="navigate" class="cursor-pointer transition-all hover:scale-105" type="button">
			<div class="relative">
				<img
					:src="$cdnify(props.img, 512)"
					class="rounded-xl overflow-hidden shadow-md shadow-gray-600"
					:title="`${props.asset} - ${props.collection}`"
				/>
				<div class="absolute -top-4 -left-4 w-1/2">
					<img src="/image/sold.png" class="-rotate-12" />
				</div>
			</div>
			<div class="mt-4">
				<div class="font-bold text-xl">{{ $format(humanReadablePrice(props.bpx_price)) }} BPX</div>
				<div>
					<to-usd :bpx="props.bpx_price" :is-decimal="false" :force-decimals="true" />
				</div>
			</div>
		</button>
	</router-link>
</template>
<script lang="ts" setup>
import { defineComponent, PropType } from 'vue'
import ToUsd from './ToUsd.vue'
import { humanReadablePrice } from '@/util/currencyFormat'

const props = defineProps({
	img: {
		required: true,
		type: String,
	},
	asset: { required: true, type: String },
	collection: { required: true, type: String },
	bpx_price: {
		required: true,
		type: [String, Number],
	},
	url: {
		required: true,
		type: String,
	},
})
</script>
