<template>
	<div>
		<auction-breadcrumbs :group="group" />

		<div v-if="!state.loaded">
			<p class="text-center p-6 sm:p-8">Loading Auctions...</p>
		</div>
		<div v-else class="h-full flex-auto p-6 sm:p-8">
			<div class="flex items-center gap-4"></div>

			<div class="h-full sm:flex justify-start">
				<token-filter
					:tags="auctionTags"
					:group="group"
					class="md:min-w-[275px] lg:min-w-[350px] md:block"
					:class="!state.showTokenFilter ? ['hidden'] : []"
					@close="state.showTokenFilter = false"
				/>

				<div class="mt-6 md:mt-0 md:ml-6 flex-auto max-w-screen-md space-y-6 overflow-y-auto">
					<div class="flex items-center justify-between md:justify-end px-2 py-2">
						<button
							class="md:hidden inline-block leading-0 drop-shadow-md rounded-xl px-3 aspect-square bg-white hover:drop-shadow-lg hover:text-sky-600 hover:border-sky-600"
							@click="state.showTokenFilter = true"
						>
							<i class="inline-block fa-fw fa-solid fa-bars-filter"></i>
						</button>

						<div class="flex justify-end items-center space-x-2 mb-2">
							<div class="font-poppins inline-block mr-2 text-lg">Sort</div>
							<select class="rounded-md shadow-md border-slate-300" v-model="sortRef">
								<option v-for="sort in Sorts" :key="`sort-${sort}`" :value="sort">
									{{ SortLabels[sort] }}
								</option>
							</select>
						</div>
					</div>

					<auction-listings :auctions="filteredAuctions" v-if="filteredAuctions.length" />
					<div v-else>
						<p class="text-center text-lg text-slate-400">No auctions found matching your filters.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, onMounted, PropType, ref, reactive, computed, customRef } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import AuctionTile from '@/components/AuctionTile.vue'
import MainNavigation from '../components/MainNavigation.vue'
import TokenFilter from '@/components/TokenFilter.vue'
import AuctionBreadcrumbs from '@/components/AuctionBreadcrumbs.vue'
import { useAuctionStore } from '@/stores/AuctionStore'
import { useTaxonomyStore } from '@/stores/TaxonomyStore'
import { Auction } from '@/types/Auction'
import AuctionListings from '@/components/AuctionListings.vue'
import { DateTime } from 'ts-luxon'
import BN from 'bn.js'
import { Sorts, SortLabels, useAuctionSort } from '@/util/AuctionSort'
import { useAppStore } from '@/stores/AppStore'
// import { trigger } from '@vue/reactivity'

interface IndexState {
	sort: String
	loaded: boolean
	showTokenFilter: true
}

export default defineComponent({
	components: {
		MainNavigation,
		RouterLink,
		AuctionTile,
		TokenFilter,
		AuctionListings,
		AuctionBreadcrumbs,
	},
	setup(props) {
		const appStore = useAppStore()
		const auctionStore = useAuctionStore()
		const taxonomyStore = useTaxonomyStore()
		const route = useRoute()
		const getSortFunction = useAuctionSort()
		const state: IndexState = reactive({
			loaded: false,
			showTokenFilter: false,
		})

		const sortRef = customRef((track, trigger) => ({
			get() {
				track()
				return appStore.auctionSort(group.value.id)
			},
			set(value) {
				appStore.setAuctionSort(group.value.id, value)
				trigger()
			},
		}))

		onMounted(() => {
			const promises = [auctionStore.fetchAuctions(), taxonomyStore.fetchTaxonomy()]

			Promise.all(promises).then(() => {
				state.loaded = true
			})
		})

		const filterContainer = ref(null)
		function toggleFilters() {
			if (filterContainer.value) filterContainer.value.classList.toggle('hidden')
		}

		const group = computed(() => {
			return auctionStore.auctionGroups.filter((g) => g.slug == route.params.group)[0]
		})

		const auctions = computed(() => {
			if (!state.loaded || !auctionStore.auctions.length) {
				return [] as Auction[]
			}

			return auctionStore.groupAuctions(group.value.id)
		})

		const auctionTags = computed(() => {
			let tagIds = new Set()
			auctions.value.map((a) => {
				a.tags.map((t) => tagIds.add(t.id))
			})

			return tagIds
		})

		const filteredAuctions = computed(() => {
			return sortAuctions(
				auctionStore.filteredAuctions.filter((a) => a.auction_group.id == group.value.id),
				sortRef.value
			)
		})

		function sortAuctions(auctions: Auction[], sort: String): Auction[] {
			return auctions.sort(getSortFunction(sort))
		}

		return {
			state,
			appStore,
			// filterContainer,
			toggleFilters,
			auctionStore,
			Sorts,
			SortLabels,
			sortRef,
			filteredAuctions,
			group,
			auctions,
			auctionTags,
		}
	},
})
</script>
