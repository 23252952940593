<template>
	<router-link
		class="flex flex-col h-full"
		:to="{
			name: 'auction',
			params: {
				auction: props.auction.id,
				group: props.auction.auction_group.slug,
			},
		}"
	>
		<div class="rounded-md overflow-hidden p-6 flex-auto max-h-[350px] flex items-center justify-center">
			<img
				class="rounded-md border-2 border-gray-300 block max-h-full"
				v-if="images.length"
				:src="cdnURL(images[0].uri)"
			/>
		</div>
		<div class="my-2">
			<h2 class="font-bold px-2 leading-5">
				{{ props.auction.title }}
			</h2>
			<div class="px-2 mt-2">
				<p class="text-right text-sm text-gray-800 font-semibold">
					{{ $format(currentBid.toDecimal()) }}
					{{ appStore.tokenSymbol }}
				</p>
				<div class="flex justify-between text-xs text-gray-500">
					<p class="">
						<i class="fa-fw fa-solid fa-gavel text-slate-400"></i>
						{{ props.auction.bids?.length }}
						<pluralize :amount="props.auction.bids?.length" singular="Bid" plural="Bids" />
					</p>
					<time-remaining :auction="props.auction" class="text-xs" />
				</div>
			</div>
		</div>
	</router-link>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { AuctionImage, Auction } from '@/types/Auction'
import { RouterLink } from 'vue-router'
import Currency from '../types/Currency'
import { countdown } from '@/util/countdownAuction'
import { useAppStore } from '../stores/AppStore'
import { cdnURL } from '@/util/cdn'
import TimeRemaining from './TimeRemaining.vue'
import { useAuctionStatusStore } from '@/stores/AuctionStatusStore'

export default defineComponent({
	components: { RouterLink, TimeRemaining },
	props: {
		auction: {
			type: Object as PropType<Auction>,
			required: true,
		},
	},
	setup(props) {
		const appStore = useAppStore()
		const { duration, finished, countState, Target } = countdown(props.auction, useAuctionStatusStore())

		const timeRemaining = computed(() => {
			const makeLabel = function (): any {
				for (const unit of ['Days', 'Hours', 'Minutes', 'Seconds']) {
					if (duration[unit.toLowerCase()] > 0 || unit == 'Seconds') {
						const val = duration[unit.toLowerCase()]
						let label = unit

						if (val == 1) {
							label = label.substring(0, label.length - 1)
						}

						return { val, label: label.toLowerCase() }
					}
				}
			}

			const { val, label } = makeLabel()

			if (finished.value) {
				return `Ended ${val} ${label} ago`
			} else if (countState.value == Target.CountToStart) {
				return `Begins in ${val} ${label}`
			} else {
				// if (countState.value == Target.CountToEnd) {
				return `Ends in ${val} ${label}`
				// } else {
				// return `${val} ${label} until extended`
			}
		})

		const currentBid = computed(() => {
			return new Currency(props.auction.high_bid || props.auction.starting_bid, null)
		})

		const images = computed((): AuctionImage[] => {
			if (props.auction.images.length) {
				return props.auction.images
			}

			return [] as AuctionImage[]
		})

		return {
			props,
			appStore,
			images,
			currentBid,
			Currency,
			timeRemaining,
			cdnURL,
		}
	},
})
</script>
