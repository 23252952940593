<template>
	<div class="relative px-4 mt-4">
		<AccountActivityTable :data-loader="loadActivity" />
	</div>
</template>
<script lang="ts" setup>
import AccountActivityTable from '@/components/NewActivityTable.vue'
import { useAccountStore } from '@/stores/AccountStore'

const accountStore = useAccountStore()

async function loadActivity(page: number | null = null): Promise<{ data: Object; meta: Object }> {
	return (await accountStore.storeActivityAccount(page)) as Promise<{ data: Object; meta: Object }>
}
</script>
