import metamask from '@/util/metamask'
import Auction from '@/abi/IAuction.json'
import BN from 'bn.js'
import TxWrapper from '@/types/TxWrapper'

const auction = metamask.loadContract(Auction, import.meta.env.VITE_AUCTION_ADDRESS)

export const AUCTION_ADDRESS: string = import.meta.env.VITE_AUCTION_ADDRESS

export async function bid(auctionId: number, amount: BN | string | number): TxWrapper {
	let bid: string
	if (typeof amount != 'number') {
		bid = amount.toString()
	} else {
		bid = String(amount)
	}
	const tx = auction.methods.bid(auctionId, bid, true)

	const gasEstimate = await tx.estimateGas({
		from: metamask.state.wallet,
		gas: 1_500_000,
	})

	return new TxWrapper(tx).send({
		from: metamask.state.wallet,
		gasLimit: Math.ceil(gasEstimate * 1.3),
	})
}

export async function getHighBid(auctionId: number): any {
	return await auction.methods.getAuctionMetadata(auctionId).call()
}

export default {
	auction,
	bid,
	getHighBid,
}
