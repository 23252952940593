import { useRoute } from 'vue-router'
import api from '@/util/api'
import { Asset } from '@/types/Asset'
import { useAuthStore } from '@/stores/AuthStore'

export async function useAsset(slug?: string, assetId?: number, user?): Promise<Asset> {
	const route = useRoute()
	const authStore = useAuthStore()
	const id = assetId || route.params.assetId
	const storefront = slug || route.params.slug

	await authStore.login()
	const token = authStore.getToken();
	const accessToken = token.data.accessToken

	const asset = await api.loadAsset(storefront, id, accessToken ?? null)

	return asset
}
