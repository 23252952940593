<template>
	<div
		class="sticky top-0 z-10 bg-gray-50 flex px-2 py-2 pb-2 drop-shadow md:fixed md:left-0 md:right-0"
		ref="scrollHeader"
	>
		<img
			class="rounded-md border-2 border-gray-300 h-[135px] flex-shrink-0"
			:src="cdnURL(props.auction.images[0].uri)"
		/>
		<div class="ml-2 flex-grow">
			<bidder-flag class="mb-2" v-if="props.auction.high_bidder" :auction="props.auction" />
			<h2 class="font-bold text-lg leading-none flex items-center">
				{{ props.auction.title }}
			</h2>
			<div class="flex items-bottom">
				<div class="flex-auto">
					<p class="text-xl font-semibold mt-.5">
						{{ $format(currentBid.toDecimal()) }}
						{{ appStore.tokenSymbol }}
					</p>

					<time-remaining :auction="props.auction" />

					<div class="mt-2" ref="scrollBidBtn">
						<button class="inline-block btn-primary" @click="$emit('bid')">Bid Now</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import Currency from '@/types/Currency'
import { countdown } from '@/util/countdownAuction'
import { cdnURL } from '@/util/cdn'
import { useAppStore } from '@/stores/AppStore'
import { Auction } from '@/types/Auction'
import { useWalletStore } from '@/stores/WalletStore'
import BidderFlag from './BidderFlag.vue'
import TimeRemaining from './TimeRemaining.vue'
import { useAuctionStatusStore } from '@/stores/AuctionStatusStore'

export default defineComponent({
	components: { BidderFlag, TimeRemaining },
	emits: ['bid'],
	props: {
		auction: {
			type: Object as PropType<Auction>,
			required: true,
		},
	},
	setup(props) {
		const walletStore = useWalletStore()
		const appStore = useAppStore()

		const { duration, finished, countState, Target } = countdown(props.auction, useAuctionStatusStore())

		const currentBid = computed(() => {
			return new Currency(props.auction.high_bid || props.auction.starting_bid, null)
		})

		const timeRemaining = computed(() => {
			const makeLabel = function (): any {
				for (const unit of ['Days', 'Hours', 'Minutes', 'Seconds']) {
					if (duration[unit.toLowerCase()] > 0 || unit == 'Seconds') {
						const val = duration[unit.toLowerCase()]
						let label = unit

						if (val == 1) {
							label = label.substring(0, label.length - 1)
						}

						return { val, label: label.toLowerCase() }
					}
				}
			}

			const { val, label } = makeLabel()

			if (finished) {
				return `Ended ${val} ${label} ago`
			} else if (countState.value == Target.CountToStart) {
				return `Begins in ${val} ${label}`
			} else if (countState.value == Target.CountToEnd) {
				return `Ends in ${val} ${label}`
			} else {
				return `${val} ${label} until extended bidding`
			}
		})

		return {
			props,
			appStore,
			walletStore,
			currentBid,
			Currency,
			timeRemaining,
			cdnURL,
		}
	},
})
</script>
