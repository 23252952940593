<template>
	<account-login v-if="!authStore.authenticated" />
	<section v-else class="bpmp-layout">
		<header class="bpmp-layout__header">
			<div class="border-b-2 border-gray-300 flex space-x-4 mx-4 mt-4">
				<!-- <router-link
					:to="{ name: 'storefront', params: { slug: storeStore.state.storefront.slug } }"
					class="px-4 text-lg font-medium text-gray-500"
					exact-active-class="pb-1 border-b-2 border-gray-500"
				>
					Items
				</router-link>
				<router-link
					:to="{ name: 'storefront-activity' }"
					class="px-4 text-lg font-medium text-gray-500"
					exact-active-class="pb-1 border-b-2 border-gray-500"
				>
					Activity
				</router-link> -->
				<router-link
					:to="{ name: 'account' }"
					class="pb-1 px-4 text-lg font-medium text-gray-500"
					exact-active-class="-mb-0.5 border-b-2 border-gray-700 text-gray-900"
				>
					Your Collection
				</router-link>
				<router-link
					:to="{ name: 'account:listings' }"
					class="pb-1 px-4 text-lg font-medium text-gray-500"
					exact-active-class="-mb-0.5 border-b-2 border-gray-500 text-gray-900"
				>
					Your Listings
				</router-link>
				<router-link
					:to="{ name: 'account:activity' }"
					class="pb-1 px-4 text-lg font-medium text-gray-500"
					exact-active-class="-mb-0.5 border-b-2 border-gray-500 text-gray-900"
				>
					Activity
				</router-link>
				<router-link
					:to="{ name: 'account:wallet' }"
					class="pb-1 px-4 text-lg font-medium text-gray-500"
					exact-active-class="-mb-0.5 border-b-2 border-gray-500 text-gray-900"
				>
					Wallet
				</router-link>
			</div>
		</header>
		<section class="bpmp-layout__content">
			<RouterView />
		</section>
	</section>
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, onUnmounted, PropType, reactive, ref } from 'vue'

// components
import AccountLogin from '@/pages/Account/AccountLogin.vue'
import { useAuthStore } from '@/stores/AuthStore'
import { useRoute } from 'vue-router'

const authStore = useAuthStore()
const route = useRoute()

onMounted(async () => {
	if (!authStore.authenticated && !(await authStore.login())) {
		window.top.location = await authStore.getLoginUri(route.path)
		return
	}
})
</script>
