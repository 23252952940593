<template>
	<div class="rounded-xl cursor-pointer">
		<div class="group relative">
			<router-link :to="{ name: 'asset', params: { slug: props.slug, assetId: props.asset.id } }">
				<slot name="event"></slot>
				<div class="flex items-center">
					<img
						v-if="props.showAsset"
						:src="props.asset.image"
						@load="state.loaded = true"
						loading="lazy"
						class="ease-in-out w-auto rounded-xl h-20 duration-300 hover:scale-10"
					/>

					<div :class="{ 'ml-4': props.showAsset }">
						<h1 v-if="props.showAsset">{{ props.asset.name }}</h1>
						<div class="font-normal text-gray-500 text-sm">
							<p v-if="props.price" class="md:hidden">
								<span class="text-gray-800" :class="[props.showAsset ? 'font-semibold' : '']">{{
									$format(humanReadablePrice(props.price)) + ' ' + $token
								}}</span>
								<span class="text-xs"> (<to-usd :bpx="props.price" :is-decimal="false" />)</span>
							</p>
							<p v-if="props.quantity">Quantity: {{ props.quantity }}</p>
							<p v-if="props.time" class="md:hidden">
								{{ props.time }}
							</p>
						</div>
					</div>
				</div>
			</router-link>
		</div>
	</div>
</template>
<script lang="ts">
import { Asset } from '@/types/Asset'
import { computed, defineComponent, PropType, reactive, ref } from 'vue'
import LoadingPlaceholder from './LoadingPlaceholder.vue'
import { useMarketStore } from '@/stores/MarketStore'
import { ShoppingBagIcon } from '@heroicons/vue/24/outline'
import { humanReadablePrice } from '@/util/currencyFormat'
import ToUsd from '@/components/ToUsd.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
	components: { LoadingPlaceholder, ShoppingBagIcon, ToUsd },
	props: {
		asset: {
			required: true,
			type: Object as PropType<Asset>,
		},
		slug: {
			required: true,
			type: String,
		},
		quantity: {
			required: false,
			type: Number,
			default: () => 0,
		},
		price: {
			required: false,
			type: Number,
		},
		username: {
			required: false,
			type: String,
		},
		time: {
			required: false,
			type: String,
		},
		showAsset: {
			required: false,
			type: Boolean,
			default: () => true,
		},
	},
	setup(props) {
		const state = reactive({
			loaded: false,
		})
		const route = useRoute()
		const marketStore = useMarketStore()
		const storefront = computed(() => {
			return marketStore.byID[props.asset.asset.storefront.id]
		})

		return {
			props,
			route,
			state,
			storefront,
			humanReadablePrice,
		}
	},
})
</script>
