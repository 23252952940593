<template>
	<section class="">
		<div class="grid grid-cols-1 gap-6">
			<div class="rounded-lg border border-gray-200 bg-gray-50 p-6">
				<h2 class="text-lg font-medium leading-6 text-gray-900 inline-flex">
					<ListBulletIcon class="stroke-2 mr-2 h-6 w-6 text-gray-900" />
					Offers:
				</h2>
				<div class="mt-1 flex flex-col text-sm text-gray-500">
					<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<div class="overflow-hidden border-t border-gray-200 mb-4">
							<div class="text-center mt-6">
								<svg
									class="mx-auto h-16 w-16 text-gray-400"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<RectangleStackIcon class="stroke-1" />
								</svg>
								<h3 class="mt-2 text-sm font-normal text-gray-900">No offers yet</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ListBulletIcon, RectangleStackIcon } from '@heroicons/vue/24/outline'
export default defineComponent({
	components: { ListBulletIcon, RectangleStackIcon },
	setup(props) {
		return {}
	},
})
</script>
