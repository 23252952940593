<template>
	<modal class="z-50">
		<template #default>
			<div class="bg-white rounded-xl overflow-hidden drop-shadow-md w-full">
				<div class="p-6 grid md:grid-cols-[2fr,3fr] gap-6 items-start">
					<div>
						<img :src="props.asset.image" class="rounded-md h-48 md:h-auto mx-auto" />
						<div class="font-poppins text-lg text-center mt-2">
							<p>{{ props.asset.name }}</p>
						</div>
					</div>
					<section class="flex flex-col h-full">
						<div class="flex flex-row justify-center border-b border-gray-200 mb-4">
							<button
								@click="currentTab = 'transfer'"
								class="p-4 grow border-b border-transparent hover:border-gray-300 hover:bg-slate-100"
								:class="currentTab === 'transfer' ? '!border-gray-400' : 'border-gray-400'"
							>
								Transfer
							</button>
							<button
								@click="
									() => {
										currentTab = 'withdraw'
										state.recipient = null
									}
								"
								class="p-4 grow border-b border-transparent hover:border-gray-300 hover:bg-slate-100"
								:class="currentTab === 'withdraw' ? '!border-gray-400' : 'border-gray-400'"
							>
								Withdraw
							</button>
						</div>

						<div v-if="currentTab === 'transfer'" class="flex flex-col grow">
							<h1 class="text-2xl font-medium font-poppins">Transfer</h1>

							<div class="mt-4 font-sm">
								<p>Transfer "{{ props.asset.name }}" to:</p>

								<div v-if="!state.confirm">
									<user-search
										:focus-on-load="true"
										@selected="selectRecipient"
										@focus="handleSearchFocus"
									/>
									<p class="text-sm text-gray-400 mx-2 mt-1 text-right">
										Search for a recipient by their username.
									</p>

									<div class="mt-4">
										<div class="flex items-center justify-end space-x-2">
											<div class="flex-grow text-right">
												<p>Quantity:</p>
												<p class="text-sm text-gray-400">
													Available: {{ props.maxTransferrable }}
												</p>
											</div>
											<div class="w-1/2 sm:w-1/4">
												<input
													class="w-full rounded-xl border-gray-200"
													type="number"
													v-model="state.quantity"
												/>
											</div>
										</div>
									</div>
								</div>
								<div v-else class="text-lg space-y-2 mt-4 h-full flex flex-col">
									<div class="">
										<span class="font-medium">Recipient:</span>
										<span v-if="state.recipient"> @{{ state.recipient.username }}</span>
									</div>

									<div class="">
										<span class="font-medium">Quantity:</span>
										<span> &nbsp;{{ state.quantity }}</span>
									</div>

									<div v-if="!state.success" class="">
										<p class="text-left">
											<button
												class="btn bg-gray-300 text-gray-600 hover:bg-gray-300/80"
												@click="state.confirm = false"
											>
												Edit Transfer
											</button>
										</p>
									</div>

									<div class="flex-shrink"></div>

									<div v-if="!state.success">
										<div class="mt-6 bg-red-100 rounded-xl px-4 py-4 text-red-600 space-y-2">
											<p class="font text-2xl">
												<span
													class="inline-flex justify-center items-center px-3 bg-red-600 text-white text-xl aspect-square rounded-full leading-none"
												>
													<i class="fa fa-exclamation"></i>
												</span>
												Warning
											</p>
											<p class="text-base">
												This action <strong class="uppercase">cannot</strong> be un-done. Double
												check your intended recipient before pressing <em>Confirm</em> below.
											</p>
										</div>
									</div>
									<div v-else>
										<div class="mt-6 bg-green-100 rounded-xl px-4 py-4 text-green-600 space-y-2">
											<p class="font text-2xl">
												<span
													class="inline-flex justify-center items-center px-3 bg-green-600 text-white text-xl aspect-square rounded-full leading-none mr-2"
												>
													<i class="fa fa-exclamation"></i>
												</span>
												<span class="capitalize">Transaction</span> Complete
											</p>
											<p class="text-base">Your transfer is complete.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="currentTab === 'withdraw'" class="flex flex-col grow">
							<template v-if="!state.success && !state.txInProgress">
								<h1 class="text-2xl font-medium font-poppins">Withdraw</h1>
								<div class="mt-4 font-sm">
									<p>
										Transfer "{{ props.asset.name }}" to
										<wallet-address :address="metamask.state.wallet" />
									</p>
								</div>

								<wallet-connected>
									<template #default>
										<div class="mt-12">
											<button
												class="w-full text-xl font-medium bg-slate-400 text-slate-600 enabled:bg-blue-600 enabled:text-slate-50 enabled:hover:saturate-150 p-4 text-center rounded-lg transition-all"
												@click="metamask.connect"
											>
												Connect Wallet
											</button>
										</div>
									</template>

									<template #connected>
										<div>
											<div class="text-center bg-slate-200 text-slate-800 py-4 rounded-lg my-6">
												<div class="text-base font-bold">You are withdrawing to:</div>
												<div class="text-lg font-mono">
													<wallet-address :address="metamask.state.wallet" />
												</div>
											</div>
											<div class="mt-4">
												<div class="flex items-center justify-end space-x-2">
													<div class="flex-grow text-right">
														<p>Quantity:</p>
														<p class="text-sm text-gray-400">
															Available: {{ props.maxTransferrable }}
														</p>
													</div>
													<div class="w-1/2 sm:w-1/4">
														<input
															class="w-full rounded-xl border-gray-200"
															type="number"
															v-model="state.quantity"
															:disabled="state.transferring"
														/>
													</div>
												</div>
											</div>
										</div>
									</template>
								</wallet-connected>
							</template>

							<template v-if="state.txInProgress">
								<div class="flex flex-col h-full justify-center items-center">
									<p class="mb-4">
										{{
											!state.isMetaMaskConfirmed
												? 'Please complete the transaction in Meta Mask'
												: 'Transaction pending...'
										}}
									</p>
									<i class="fa-2xl fa-sharp fa-solid fa-spinner-third fa-spin"></i>
								</div>
							</template>

							<div v-if="state.confirm && !state.success && !state.txInProgress">
								<div class="mt-6 bg-red-100 rounded-xl px-4 py-4 text-red-600 space-y-2">
									<p class="font text-2xl">
										<span
											class="inline-flex justify-center items-center px-3 bg-red-600 text-white text-xl aspect-square rounded-full leading-none mr-2"
										>
											<i class="fa fa-exclamation"></i>
										</span>
										Warning
									</p>
									<p class="text-base">
										This action <strong class="uppercase">cannot</strong> be un-done. Double check
										your intended wallet before pressing <em>Confirm</em> below.
									</p>
								</div>
							</div>
							<div v-if="state.confirm && state.success && !state.txInProgress">
								<div class="mt-6 bg-green-100 rounded-xl px-4 py-4 text-green-600 space-y-2">
									<p class="font text-2xl">
										<span
											class="inline-flex justify-center items-center px-3 bg-green-600 text-white text-xl aspect-square rounded-full leading-none mr-2"
										>
											<i class="fa fa-exclamation"></i>
										</span>
										<span class="capitalize">Transaction</span> Complete
									</p>
									<p class="text-base">Your withdrawal is complete.</p>
								</div>
							</div>
						</div>
					</section>
				</div>
				<div class="mt-4 bg-gray-100 text-right space-x-6 pt-6 pb-4 px-6">
					<button v-if="!state.success && !state.txInProgress" class="text-gray-500" @click.stop="cancel">
						Cancel
					</button>

					<button
						v-if="!state.confirm"
						class="btn-primary-lg text-white space-x-1"
						:disabled="!formValid && currentTab === 'transfer'"
						@click="send"
					>
						<i class="fa-solid fa-paper-plane" />
						Send
					</button>

					<button
						v-if="state.confirm && !state.success"
						class="btn-primary-lg text-white space-x-1"
						@click="confirmSend"
						:disabled="state.transferring"
					>
						<span v-if="!state.transferring">
							<i class="fa-solid fa-paper-plane" />
							Confirm
						</span>
						<span v-else>
							<i class="fa-sharp fa-solid fa-spinner-third fa-spin"></i>
							Sending
						</span>
					</button>

					<a
						v-if="state.confirm && state.success && state.tx !== null"
						:href="`https://polygonscan.com/tx/${state.tx?.transactionHash}`"
						target="_blank"
						class="btn-primary-lg text-white space-x-6 hover:bg-sky-600"
						>View Transaction</a
					>

					<button
						v-if="state.confirm && state.success"
						class="btn-primary-lg text-white space-x-6"
						@click="transferComplete"
					>
						Done
					</button>
				</div>
			</div>
		</template>
	</modal>
</template>
<script lang="ts" setup>
import { computed, defineComponent, onUnmounted, PropType, reactive, ref } from 'vue'
import { Asset } from '@/types/Asset'
import { useUserProfileStore } from '@/stores/UserProfileStore'
import UserSearch from '@/components/widgets/UserSearch.vue'
import { useMarketStore } from '@/stores/MarketStore'
import { useNewWalletStore } from '@/stores/NewWalletStore'
import { useAuthStore } from '@/stores/AuthStore'
import Bugsnag from '@bugsnag/js'
import { wasNotDeclined } from '@/util/Errors'

import IGatewayModule from '@/abi/IGatewayModule.json'
import { BN } from 'bn.js'

import sleep from '@/util/sleep'
import metamask from '@/util/metamask'
import Web3 from 'web3'

const ETH_SAFE_GATEWAY_ADDRESS = import.meta.env.VITE_ETH_SAFE_GATEWAY_ADDRESS

const currentTab = ref('transfer')

const userProfileStore = useUserProfileStore()
const props = withDefaults(
	defineProps<{
		asset: Asset
		maxTransferrable: Number
	}>(),
	{}
)

const emit = defineEmits<{
	close: []
	complete: [Asset]
}>()

interface SearchResult {
	username: string
	objectID: string
}

const state = reactive({
	quantity: 1,
	recipient: null as SearchResult | null,
	confirm: false,
	pin: null,
	skipConfirm: false,
	transferring: false,
	success: null as boolean | null,
	message: null as string | null,
	currentWithdrawalAmount: null,
	txInProgress: false,
	txComplete: false,
	txError: null,
	tx: null,
	isMetaMaskConfirmed: false,
})

function selectRecipient(user: SearchResult | null) {
	state.recipient = user
	if (state.recipient) {
		setTimeout(monitorEnterKey, 50)
		console.warn(user)
	}

	console.log(user)
}

let listening = false
function monitorEnterKey() {
	if (!listening) {
		console.log('monitoring enter key')
		listening = true
		window.addEventListener('keyup', handleEnterPress)
	}
}

function handleSearchFocus() {
	console.log('unmonitoring enter key')
	stopEnterListener()
}

function stopEnterListener() {
	listening = false
	window.removeEventListener('keyup', handleEnterPress)
}

function handleEnterPress($evt) {
	console.log($evt.code)
	if ($evt.code == 'Enter') {
		console.log('confirm, success', state.confirm, state.success)
		if (!state.confirm) {
			console.log('advancing to confirm step')
			send()
		} else if (!state.success) {
			console.log('executing send')
			confirmSend()
			stopEnterListener()
		}
	}
}

onUnmounted(() => {
	handleSearchFocus()
})

const formValid = computed(() => {
	if (state.quantity < 1) return false
	if (state.quantity > props.maxTransferrable) return false
	if (state.recipient == null && currentTab.value === 'transfer') return false

	return true
})

function send() {
	if (currentTab.value === 'withdraw') {
		// withdraw logic here
		state.confirm = true
	}

	if (currentTab.value === 'transfer') {
		if (!formValid.value) {
			alert('Can not transfer.')
			return false
		}

		state.confirm = true
	}
}

async function withdrawAsset() {
	const newWalletStore = useNewWalletStore()
	const authStore = useAuthStore()
	state.txError = null
	state.transferring = true
	state.txInProgress = true
	state.txComplete = false

	if (metamask.state.chain !== ETH_SAFE_GATEWAY_ADDRESS) {
		const res = await metamask.requestChain(137)
		console.log(res, 'res')
		if (!res) {
			state.transferring = false
			state.txInProgress = false
			return
		}
	}

	const id = authStore.user
	const wallet = metamask.state.wallet

	const asset = props.asset.asset_identifier
	const amount = state.quantity

	let authedTx
	try {
		authedTx = await newWalletStore.withdraw(asset, amount.toString(), wallet)
	} catch (e) {
		console.error({ authedTx: e })
		state.transferring = false
		state.txInProgress = false
		state.txError = e.toString()
		return
	}

	const gateway = await metamask.loadContract(IGatewayModule, authedTx.gateway)

	const execute = await gateway.methods.execute(
		authedTx.safe,
		authedTx.destination,
		authedTx.transaction,
		false,
		authedTx.expiration,
		authedTx.identifier,
		authedTx.counter,
		authedTx.salt,
		authedTx.signature
	)

	console.log(execute, 'execture')

	let gasEstimate
	console.log(metamask.state.wallet, 'wallet')
	try {
		gasEstimate = await execute.estimateGas({
			from: metamask.state.wallet,
			gas: 500_000,
		})
		console.log(gasEstimate, 'gas')
	} catch (e) {
		console.error({ estimateGas: e, metamask: metamask.state })
		state.transferring = false
		state.txInProgress = false
		state.txError = e.toString()
		return
	}

	gasEstimate = Math.ceil(gasEstimate * 1.3)

	const wait = sleep(1)

	const receipt = await execute
		.send({
			from: metamask.state.wallet,
			gas: gasEstimate,
			maxPriorityFeePerGas: null,
			maxFeePerGas: null,
		})
		.on('transactionHash', (hash) => {
			console.log(hash)
			state.isMetaMaskConfirmed = true
		})
		.on('receipt', async (r) => {
			await wait

			console.log({ receipt: r })

			state.txComplete = true
			state.transferring = false
			state.txInProgress = false
			state.tx = r
			state.success = true
			state.isMetaMaskConfirmed = false
		})
		.catch((err) => {
			state.txComplete = false
			state.transferring = false
			state.txInProgress = false
			state.success = false
			state.isMetaMaskConfirmed = false
			console.log(err)
			if (wasNotDeclined(err)) {
				state.txError = err
				Bugsnag.notify(err)
			} else {
				transferComplete()
			}
		})
}

async function confirmSend() {
	if (currentTab.value === 'transfer') {
		state.transferring = true
		const wait = sleep(0.75)
		if (!formValid.value) {
			await wait
			alert('Transfer settings are invalid')
			state.transferring = false
			return false
		}

		const marketStore = useMarketStore()

		const response = await marketStore.otcTransfer(
			[
				{
					asset_id: props.asset.asset_identifier,
					quantity: state.quantity,
				},
			],
			state.recipient.username,
			state.pin
		)

		await wait

		if (response.success) {
			state.success = true
		} else {
			state.success = false
			state.message = response.message
			console.error(state.message)
		}

		state.transferring = false
	}
	if (currentTab.value === 'withdraw') {
		withdrawAsset()
	}
}

function cancel() {
	state.quantity = 1
	state.recipient = null
	emit('close')
}

function transferComplete() {
	emit('complete', props.asset)
}
</script>
