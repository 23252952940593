<template>
	<modal class="z-50">
		<div class="bg-slate-100 rounded-xl overflow-hidden">
			<div class="p-4">
				<h1 class="text-red-500 text-3xl font-bold">
					<i class="fas fa-exclamation-triangle"></i>
					Error!
				</h1>
				<component class="mt-4" :error="props.error" :is="props.component" />
			</div>
			<div class="bg-white border-t-2 border-slate-200 p-4">
				<button class="btn-primary-lg block w-full mt-2" @click.stop="$emit('close')">Ok</button>
			</div>
		</div>
	</modal>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
	props: {
		component: {
			required: true,
		},
		error: {
			required: false,
			type: null as PropType<String | null>,
			default: () => new Error(),
		},
	},

	emits: ['close'],

	setup(props) {
		return {
			props,
		}
	},
})
</script>
